import { Paper } from "@mui/material";
import StandardCardProps from "./AppCard.props";
import { useCardStyles } from "./AppCard.styles";
import React from "react";

export default function Card({
  children,
  elevation = 3,
  className = "",
  style = {},
}: StandardCardProps) {
  const CardStyles = useCardStyles(style);
  return (
    <Paper
      className={`${CardStyles.standard} rounded-3xl ${className}`}
      elevation={elevation}
    >
      {children}
    </Paper>
  );
}
