import React, { useState } from "react";
import { ForwardedRef, forwardRef } from "react";
import { BookingDetailsDefaultValues } from "../WebCheckInForms/WebCheckInForms.props";
// const fetchReservationId = BookingDetailsDefaultValues.reservationId;
export const PayPal = forwardRef(function PayPalComponent(
  {
    currencyCode = "EUR",
    postUrl,
    companyEmail,
    amount,
    returnLink,
    notify_url,
    cancel_url,
    itemName,
    reservationId,
  }: {
    postUrl: string;
    reservationId: number;
    companyEmail: string;
    amount: number;
    returnLink: string;
    notify_url: string;
    cancel_url: string;
    currencyCode?: string;
    itemName: string;
  },
  ref: ForwardedRef<HTMLFormElement>
) {
  return (
    <>
      <form action={postUrl} method="post" target="_top" ref={ref}>
        <input type="text" name="cmd" value="_xclick" />
        <input type="text" name="business" value={companyEmail} />
        <input type="text" name="currency_code" value={currencyCode} />
        <input type="text" name="item_name" value={itemName} />

        <input type="text" name="amount" id="percentvaluetext" value={amount} />

        <input type="text" name="return" value={returnLink} />

        <input type="text" name="notify_url" value={notify_url} />

        <input type="text" name="cancel_url" value={cancel_url} />

        <input type="text" name="cbt" value="Torna al sito" />
        <input type="text" name="rm" value="2" />
        <input type="text" name="no_shipping" value="1" />
        <input type="text" name="UTF-8" value="charset" />
        <input type="text" name="paypal" value="page_style" />
        <input type="text" name="no_note" value="1" />
        <input type="text" name="custom" id="driver" value={reservationId} />
        <input type="text" name="privacy" id="privacy" value="0" />
        {/*<input type="hidden" name="cmd" value="_xclick" />*/}

        {/*<input type="hidden" name="business" value={companyEmail} />*/}

        {/*<input type="hidden" name="currency_code" value={currencyCode} />*/}
        {/*<input type="hidden" name="item_name" value={itemName} />*/}

        {/*<input*/}
        {/*  type="hidden"*/}
        {/*  name="amount"*/}
        {/*  id="percentvaluehidden"*/}
        {/*  value={amount}*/}
        {/*/>*/}

        {/*<input type="hidden" name="return" value={returnLink} />*/}

        {/*<input type="hidden" name="notify_url" value={notify_url} />*/}

        {/*<input type="hidden" name="cancel_url" value={cancel_url} />*/}

        {/*<input type="hidden" name="cbt" value="Torna al sito" />*/}
        {/*<input type="hidden" name="rm" value="2" />*/}
        {/*<input type="hidden" name="no_shipping" value="1" />*/}
        {/*<input type="hidden" name="UTF-8" value="charset" />*/}
        {/*<input type="hidden" name="paypal" value="page_style" />*/}
        {/*<input type="hidden" name="no_note" value="1" />*/}
        {/*<input type="hidden" name="custom" id="driver" value="1" />*/}
        {/*<input type="hidden" name="privacy" id="privacy" value="0" />*/}
        <input type={"submit"} value={"SUBMIT"} />
      </form>
    </>
  );
});
