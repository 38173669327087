import * as React from "react";

import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import { FormFieldErrorMessage } from "../../components/UI/FormFieldErrorMessage/FormFieldErrorMessage";
import { SoleTraderFormFields } from "../../components/Layouts/WebCheckInForms/FormOptions";
import { useForm } from "react-hook-form";
import { useWebCheckInForm } from "../../hooks/WebCheckInForm";

export function TestPage(): JSX.Element {
  const [, , formFields] = useWebCheckInForm(SoleTraderFormFields);
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<string, any>>({
    mode: "onBlur",
    defaultValues: {
      company: "ABC",
    },
  });
  return (
    <form
      onSubmit={handleSubmit((e) => {
        console.log(e);
      })}
    >
      <AppRadioButton
        {...register("test", {
          required: {
            value: true,
            message: "this is required",
          },
        })}
        value={getValues("test")}
        options={["Marketing", "Not Marketing"]}
        label={"test"}
        onChange={(e) => {
          setValue("test", e, {
            shouldValidate: true,
          });
        }}
      />
      <FormFieldErrorMessage>{errors?.test?.message}</FormFieldErrorMessage>
      <button type={"submit"}>SUBMIT</button>
    </form>
  );
}
