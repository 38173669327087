import { useSelector } from "react-redux";
const WebCheckInGetters = {
  getIsAvailable() {
    return useSelector((state) => state.webCheckIn.isAvailable);
  },
  getBookingDetails() {
    return useSelector((state) => state.webCheckIn.bookingDetails);
  },
};
export { WebCheckInGetters };
