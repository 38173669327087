import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import ImageUploading from "react-images-uploading";
import NotAuthorisedPage from "../notAuthorisedPage";
import ProfileNavigation from "./ProfileNavigation";
import { BookingGetters } from "../../store/onlineBooking/getters";
import { t } from "i18next";

export function Profile() {
  const [images, setImages] = React.useState([]);
  const appSettings = BookingGetters?.getAppSettings();
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    setProfileDetails((prevState) => ({
      ...prevState,
      userImage: imageList,
    }));
    console.log(profileDetails);
    setIsImage(false);
  };
  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    BookingApi.getUserProfileByUserId(id).then((e) => {
      // console.log(e.data.onlineUser[0]);
      setProfileDetails(e.data.onlineUser[0]);
    });
  }, []);

  const [profileDetails, setProfileDetails] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userImage: "",
  });
  const [isImage, setIsImage] = useState(true);
  const mobile = profileDetails?.phoneNumber?.toString();

  function handleSubmit(event) {
    const id: any = localStorage.getItem("userID");
    event.preventDefault();
    BookingApi.UpdateUserProfileById(id, profileDetails).then((e) => {
      if (e.data.status) {
        alert("User Updated Successfully");
      } else {
        alert("Unable to Update");
        window.location.reload();
      }
      return e.data;
    });
  }
  const [showReserve, setShowReserve] = useState<boolean>(
    !!appSettings?.reservedArea
  );
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowReserve(e.reservedArea);
    });
  }, [showReserve]);

  return (
    <div className="flex">
      <ProfileNavigation />
      {showReserve ? (
        <div className="flex flex-col pt-12">
          <div className="text-[#333] mb-4 ml-16">
            <h1 className="text-[28px] font-normal">
              {t("Account information")}
            </h1>
            <h3 className="text-[20px] text-[#838181]">
              {t(
                "Your account information is here you can edit your details from here"
              )}
            </h3>
          </div>
          <form className="mr-32" onSubmit={handleSubmit}>
            <Container component="main" maxWidth="xs">
              <Card sx={{ minWidth: 600 }}>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  boxShadow={3}
                >
                  <Grid item xs={3}>
                    <Grid container alignItems="center">
                      <Card sx={{ minWidth: 440 }}>
                        <Box
                          sx={{
                            marginBottom: 5,
                            padding: 2,
                            display: "flex",
                          }}
                        >
                          <div>
                            <div className="App">
                              <ImageUploading
                                value={images}
                                onChange={onChange}
                                dataURLKey="data_url"
                              >
                                {({
                                  imageList,
                                  onImageUpload,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                                }) => (
                                  <div className="upload__image-wrapper">
                                    {isImage == true ? (
                                      <button
                                        style={
                                          isDragging
                                            ? { color: "red" }
                                            : undefined
                                        }
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        <Avatar
                                          src="/assets/images/AvtarProfilePage.png"
                                          sx={{
                                            margin: 2,
                                            width: 70,
                                            height: 70,
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <h1 className="hidden"> hhh</h1>
                                    )}
                                    &nbsp;
                                    {imageList.map((image, index) => (
                                      <div key={index}>
                                        <img
                                          src={image["data_url"]}
                                          alt=""
                                          width="100"
                                          className="max-w-full h-auto rounded-full"
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </ImageUploading>
                            </div>
                          </div>
                          <div className="p-4">
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              label="First Name"
                              inputProps={{ readOnly: true }}
                              value={profileDetails?.firstName}
                              name="firstName"
                            />

                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              inputProps={{ readOnly: true }}
                              name="lastName"
                              label="lastName"
                              value={profileDetails?.lastName}
                              id="lastName"
                            />
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              label="Email"
                              inputProps={{ readOnly: true }}
                              name="email"
                              value={profileDetails?.email}
                              id="email"
                            />
                            <TextField
                              name="phoneNumber"
                              id="phoneNumber"
                              value={profileDetails?.phoneNumber}
                              margin="normal"
                              required
                              fullWidth
                              label="Enter Phone Number"
                            />
                            <div className="flex gap-4 flex-row-reverse">
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                  gap: 3,
                                  fontWeight: "bold",
                                  bgcolor: "#0202D53",
                                  textTransform: "capitalize",
                                }}
                                style={{
                                  backgroundColor: "#020D53",
                                }}
                              >
                                Save
                              </Button>

                              <Button
                                type="submit"
                                variant="outlined"
                                sx={{
                                  gap: 3,
                                  fontWeight: "bold",
                                  borderColor: "#000",
                                  textTransform: "capitalize",
                                }}
                                style={{ color: "#000" }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </form>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
