import { useSelector } from "react-redux";
import {
  BookingFormConfiguration,
  chooseVehicleConfiguration,
  FooterConfiguration,
  HeaderConfiguration,
  HomePageConfiguration,
} from "../../@types/Pages/PageConfiguration";
import { Store as RawStore } from "../index";
type Store = typeof RawStore;
export const PageConfigGetters = {
  getHomePageConfig(): HomePageConfiguration {
    return useSelector<Store, HomePageConfiguration>((state: Store) => {
      return state.pageConfig.homePage || {};
    });
  },
  getBookingFormConfig(): BookingFormConfiguration {
    return useSelector<Store, BookingFormConfiguration>(
      (state: Store) => state.pageConfig.bookingForm || []
    );
  },
  getAppHeaderConfig(): HeaderConfiguration {
    return useSelector<Store, HeaderConfiguration>(
      (state: Store) => state.pageConfig.header || {}
    );
  },
  getAppFooterConfig(): FooterConfiguration {
    return useSelector<Store, FooterConfiguration>(
      (state: Store) => state.pageConfig.footer || {}
    );
  },
  getChooseVehicleConfig(): chooseVehicleConfiguration {
    return useSelector<Store, chooseVehicleConfiguration>(
      (state: Store) => state.pageConfig.chooseVehicle || {}
    );
  },
};
