import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import reducers from "./reducers";
import states from "./states";
import { Slice } from "@reduxjs/toolkit/src/createSlice";
import { PageConfigurations } from "./states";

const PageConfigSlice: Slice<
  PageConfigurations,
  SliceCaseReducers<PageConfigurations>,
  string
> = createSlice({
  name: "PageConfig",
  initialState: states,
  reducers: reducers,
});
export const PageConfigActions = PageConfigSlice.actions;
export const PageConfigReducers = PageConfigSlice.reducer;
export { PageConfigReducers as default };
