import CSS from "csstype";
import { makeStyles } from "@mui/styles";

interface StylesType extends CSS.Properties {
  rounded?: boolean;
  size?: string;
}
const UseButtonStyles = makeStyles(() => ({
  standard(props: StylesType) {
    return {
      "&.MuiButton-root": {
        ...props,
        borderRadius: props.rounded ? "20rem" : props.borderRadius,
        height: props.size === "x-small" ? "2rem" : "100%",
        fontSize: props.size === "x-small" ? "0.85rem" : null,
        backgroundColor: props.backgroundColor === "transparent",
        //justifyContent: "unset", // text alignment in button was going to left
        // justifyContent: "unset !important",
        minHeight: "unset !important",
        minWidth: "unset !important",
        // width: "50%",
      },
    };
  },
}));
export { UseButtonStyles as default };
