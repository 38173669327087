import * as React from "react";
import { t } from "i18next";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BOOKING_ACTIONS } from "../../../store/onlineBooking";
import { useNavigate } from "react-router-dom";
import BookingApi from "../../../API/Apis/BookingApi";
import { CustomerDetailsResponse_2 } from "../../../@types/DTO/ApiResponses";
import { UserRegistration } from "../../../@types/DTO/Booking";
import { UserRegistrationRequest } from "../../../@types/DTO/UserRegistration";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ErrorSharp } from "@mui/icons-material";
import { IconButton, InputAdornment, Modal } from "@mui/material";
import { color } from "@mui/system";
import NotAuthorisedPage from "../../../views/notAuthorisedPage";
import MyReservationsPage from "../../../views/reservations";
import { WebCheckInFormFields } from "../WebCheckInForms/FormOptions";
import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";

const App = () => {
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
};

// function Copyright(props: any) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Myrentsoftware.com
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const theme = createTheme();

interface RegistrationFormFields {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  birthDate: string;
  onlineUserType?: number;
  onlineUserRole?: number;
  onlineUserStatus?: number;
  id?: any;
}
// .matches(
//   /^.*(?=.{5,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
//   "Password must contain at least 5 characters, one uppercase, one number and one special case character"
// )

export default function SignUp() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required").email("email is invalid"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    password: Yup.string()
      .required(" Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
    // acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
    birthDate: Yup.string().nullable(),
    acceptTerms: Yup.bool().required("Accept Terms is required"),
  });
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);

  //   BookingApi.registerNewUser(registrationDetails);
  //   navigate("/signin");
  // };
  const [registrationDetails, setRegistrationDetails] = useState<
    RegistrationFormFields & { confirmPassword: string }
  >({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    birthDate: "",
    onlineUserType: 3,
    onlineUserRole: 2,
    onlineUserStatus: 1,
    id: "",
  });

  const [ids, setIds] = useState("");

  const onSubmit = (data) => {
    setRegistrationDetails(data);

    BookingApi.createNewUser(data).then((data) => {
      <MyReservationsPage ids={data.data.id} />;

      if (data.data.status) {
        // alert("Registration Successfull");
        localStorage.setItem("userID", data.data.id);
        localStorage.setItem("isUserLoggedin", "true");
        navigate("/SignUpSuccess");
      } else {
        alert(data.data.message);
        if (data.data.message == "Email already exist") {
          reset({
            email: "",
          });
        }
        if (data.data.message == "Mobile number already exist") {
          reset({
            phoneNumber: "",
          });
        }
      }
    });
  };

  const [showReserve, setShowReserve] = useState<boolean>(true);
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowReserve(e.reservedArea);
      // setShowReserve(true);
    });
  }, [showReserve]);

  const [passwordType, setPasswordType] = useState("text");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [passwordType1, setPasswordType1] = useState("text");
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  return (
    <div className="grid grid-cols-2 ">
      <div className="h-fit w-fit">
        {/* <h1 className="text=[#fff] absolute text-4xl mt-6 ml-7 font-medium">
          Booking
        </h1> */}
        <img
          src="/assets/images/reserve_area_car.jpg"
          className="w-fit h-fit aspect-square "
        />
      </div>
      <div>
        <ThemeProvider theme={theme}>
          {showReserve ? (
            <div className="flex flex-col top-0 items-center justify-center w-full h-full min-h-screen">
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className=" mb-12 ml-28">
                    <span className="text-[#00000099] mr-2">
                      {" "}
                      {t("Already have an account?")}
                    </span>
                    <Link href="/signin" variant="body2">
                      <button className="bg-[#3c63ae] text-[#fff] h-8 w-20 rounded">
                        {t("Login")}
                      </button>
                    </Link>
                  </div>
                  <div>
                    <div className=""></div>
                    <h1 className="text-[#3c63ae] text-3xl font-semibold mb-3">
                      {t("Welcome to Myrent booking")}
                    </h1>
                    {/* <Typography component="h1" variant="h5">
                    Sign up
                  </Typography> */}
                    <Box
                      component="form"
                      noValidate
                      // onSubmit={handleSubmit}
                      sx={{ mt: 3 }}
                    >
                      <form autoComplete="off">
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              autoComplete="given-name"
                              required
                              fullWidth
                              id="firstName"
                              label={t("First Name")}
                              autoFocus
                              {...register("firstName")}
                              error={errors.firstName ? true : false}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errors.fullName?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              required
                              fullWidth
                              id="lastName"
                              label={t("Last Name")}
                              // name="lastName"
                              autoComplete="family-name"
                              {...register("lastName")}
                              error={errors.lastName ? true : false}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errors.lastName?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="email"
                              label={t("Email Address")}
                              // name="email"
                              autoComplete="email"
                              {...register("email")}
                              error={errors.email ? true : false}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errors.email?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="phoneNumber"
                              type="number"
                              label={t("Phone Number")}
                              autoComplete="Phone Number"
                              {...register("phoneNumber")}
                              error={errors.phoneNumber ? true : false}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errors.phoneNumber?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              // value={registrationDetails.password}
                              // onChange={(e) => {
                              //   setRegistrationDetails((prev) => {
                              //     return { ...prev, password: e.target.value };
                              //   });
                              // }}
                              required
                              fullWidth
                              // name="password"
                              label={t("Password")}
                              type={passwordType}
                              className=" content-end"
                              id="password"
                              // onClick={}
                              autoComplete="new-password"
                              {...register("password")}
                              error={errors.password ? true : false}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Button
                                      variant="text"
                                      onClick={togglePassword}
                                    >
                                      {" "}
                                      {passwordType == "password" ? (
                                        <VisibilityIcon color="primary" />
                                      ) : (
                                        <VisibilityOffIcon color="primary" />
                                      )}
                                    </Button>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <Typography variant="inherit" color="textSecondary">
                              {errors.password?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              label={t("Confirm Password")}
                              type={passwordType1}
                              id="confirm_password"
                              autoComplete="new-password"
                              {...register("confirmPassword")}
                              error={errors.confirmPassword ? true : false}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Button
                                      variant="text"
                                      onClick={togglePassword1}
                                    >
                                      {" "}
                                      {passwordType1 == "password" ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon color="primary" />
                                      )}
                                    </Button>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errors.confirmPassword?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={22}>
                            {/* <AppInputSwitcher
                              {...WebCheckInFormFields.birthDate}
                              {...register("birthDate")}
                              required={false}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className={""}
                            /> */}
                            <TextField
                              id="date"
                              label={t("Date of birth")}
                              fullWidth
                              required
                              type="date"
                              {...register("birthDate")}
                              error={errors.birthDate ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max:
                                  new Date().getFullYear() -
                                  18 +
                                  "-" +
                                  new Date().getMonth() +
                                  "-" +
                                  new Date()
                                    .getDay()
                                    .toString()
                                    .padStart(2, "0"),
                              }}
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" />
                    }
                    label={
                      <Box component="div" fontSize={15} color="#000000	">
                        I want to receive inspiration, marketing promotions and
                        updates via email.
                      </Box>
                    }
                  /> */}
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Controller
                                  control={control}
                                  required
                                  defaultValue="false"
                                  {...register("acceptTerms")}
                                  render={({ field: { onChange } }) => (
                                    <Checkbox
                                      color="primary"
                                      required
                                      onChange={(e) =>
                                        onChange(e.target.checked)
                                      }
                                    />
                                  )}
                                />
                              }
                              label={
                                <Box
                                  component="div"
                                  fontSize={15}
                                  color="#000000	"
                                  required
                                  {...register("acceptTerms")}
                                >
                                  {t("Please accept Terms and condition")}
                                </Box>
                              }
                            />
                            <Typography variant="inherit" color="red">
                              {errors.acceptTerms
                                ? "(" + errors.acceptTerms.message + ")"
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          size="large"
                          sx={{
                            mt: 3,
                            mb: 2,
                            py: 1.5,
                            bgcolor: "#3c63ae",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            "&:hover": {
                              background: "#3c63ae",
                            },
                          }}
                          onClick={handleSubmit(onSubmit)}
                        >
                          {t("Register")}
                        </Button>
                        <Grid
                          container
                          justifyContent="flex-end"
                          //  className="text-[#004188]"
                        >
                          <Grid item>
                            {/* <span className="text-[#020D53] mr-1">
                            {" "}
                            Already have an account?
                          </span>
                          <Link href="/signin" variant="body2">
                            Sign In
                          </Link> */}
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </div>
                </Box>

                {/* <Copyright sx={{ mt: 5 }} /> */}
              </Container>
            </div>
          ) : (
            <NotAuthorisedPage />
          )}
        </ThemeProvider>
      </div>
    </div>
  );
}
