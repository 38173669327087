import { Routes } from "../@types/Routes";
import HomePage from "../views/home";
import Accounts from "../views/accounts/index";
import MyReservationsPage from "../views/reservations";
import { ChooseVehiclePage } from "../views/chooseVehicle";
import { PaymentDetailsPage } from "../views/pay";
import { BookingConfirmPage } from "../views/bookingConfirmation/index";
import { SelfServicePage } from "../views/selfService";
import { WebCheckInPage } from "../views/webCheckIn";
import { SelfServiceGetters } from "../store/selfService/getters";
import { WebCheckInGetters } from "../store/webCheckIn/getters";
import { TestPage } from "../views/test/test";
import { EmbedPage } from "../views/embeded";
import SignIn from "../components/Layouts/Signinform/SignIn";
import SignUp from "../components/Layouts/SignUpform/SignUp";
import ForgotPasswordPage from "../views/forgotpassword";
import BookingGetters from "../store/onlineBooking/getters";
import { SuccessPage } from "../components/Layouts/SuccessPage/SuccessPage";
import React from "react";
import { Profile } from "../views/profile/profile";
import { ProfileForBooking } from "../views/profile/ProfileForBooking";
import { CustomerDetailFormField } from "../views/pay/UserDetailsForm";
import { CustomerDetails } from "../views/profile/customerDetails";
import CustomerDetailsList from "../views/customerDetailsList/customerDetailsList";
import { UpdateCustomer } from "../views/customerDetailsList/updateCustomer";
import UpdatePassword from "../views/forgotpassword/updatePassword";
import { SignUpSuccess } from "../components/Layouts/SignUpform/SignUpSuccess";
import { CancelledPage } from "../views/nexiBookingCancelled";
import { WireTransferConfirm } from "../views/WireTransferConfirm";
import { BookingConfirmPageStripe } from "../views/bookingConfirmationStripe";
import { CancelledPageForStripe } from "../views/stripePaymentCancelled";
import MyReservedBookings from "../views/ReservedBookings/index";
import MyRentals from "../views/Rentals/index";
import MyInvoices from "../views/Invoice/index";
import ChangePassword from "../views/forgotpassword/ChangePassword";
import BookingCard from "../views/reservations/BookingCard";
import { BookingConfirmationPayPal } from "../views/bookingConfirmationPaypal";
/**
 *
 * @description
 * Routes configuration
 *
 */

const CommonRoutes: Array<Routes> = [
  {
    path: "", // home page path
    element: <HomePage />,
  },
  {
    path: "choose/vehicle", // page to see all vehicles for booking
    element: <ChooseVehiclePage />,
  },
  {
    path: "booking/pay", // page to collect payment information
    element: <PaymentDetailsPage />,
    shouldRedirect: () => {
      const t = Object.keys(BookingGetters.getBookingDetails());
      return !t.length;
    },
    redirectUrl: "/",
  },
  {
    path: "MovementRenewal/UrlDoneChargeBook", // page to show booking is successful
    element: <BookingConfirmPage />,
  },
  {
    path: "MovementRenewal/UrlDoneStripeCharge", // page to show booking is successful
    element: <BookingConfirmPageStripe />,
  },
  {
    path: "MovementRenewal/UrlDonePaypalCharge",
    element: <BookingConfirmationPayPal />,
  },
  {
    path: "MovementRenewal/UrlBackRenew", // page to show booking is failed in case of Nexi
    element: <CancelledPage />,
  },
  {
    path: "MovementRenewal/UrlCancelStripeCharge", // page to show booking is failed in Stripe
    element: <CancelledPageForStripe />,
  },
  {
    path: "booking/confirmed", // page to show booking is successful
    element: <WireTransferConfirm />,
  },
  {
    path: "booking/web-check-in", // web check in homepage
    element: <WebCheckInPage />,
    shouldRedirect: WebCheckInGetters.getIsAvailable,
  },
  {
    path: "booking/self-service", // self-service homepage
    element: <SelfServicePage />,
    shouldRedirect: SelfServiceGetters.getIsAvailable,
  },
  /**
   *
   *@description
   * Accounts routes
   */
  {
    path: "accounts", // accounts page path
    element: <Accounts />,
  },
  {
    path: "my-reservations", // page to see all reservations
    element: <MyReservationsPage />,
  },
  {
    path: "signin", // Signin
    element: <SignIn />,
  },
  // {
  //   path: "TabsRender", // Signin
  //   element: <TabsComponent />,
  // },
  {
    path: "signup", // Signup
    element: <SignUp />,
    shouldRedirect: BookingGetters.getIsLoggedIn,
  },
  {
    path: "forgotpassword", // Forgotpassword
    element: <ForgotPasswordPage />,
  },
  {
    path: "updatePassword", // Forgotpassword
    element: <UpdatePassword />,
  },

  {
    path: "profile", //   Profile
    element: <Profile />,
  },
  {
    path: "profile2",
    element: <ProfileForBooking />,
  },
  {
    path: "customerDetails", //   Profile more infornmation
    element: <CustomerDetails />,
  },
  {
    path: "customerDetailsList", //  Customer List
    element: <CustomerDetailsList />,
  },
  {
    path: "updateCustomer", //  Customer List
    element: <UpdateCustomer />,
  },
  {
    path: "myReservedBookings",
    element: <MyReservedBookings />,
  },
  {
    path: "myRentals",
    element: <MyRentals />,
  },
  {
    path: "myInvoices",
    element: <MyInvoices />,
  },
  {
    path: "changePassword",
    element: <ChangePassword />,
  },
  {
    path: "bookingCard",
    element: <BookingCard />,
  },
];
export const Router: Array<Routes> = [
  ...CommonRoutes,
  /**
   *@description
   * Url to Embeded in Iframe
   *
   */
  {
    path: "/embed", // test route
    element: <EmbedPage />,
    children: [
      ...CommonRoutes,
      {
        path: "*", // test route
        element: <div>NOT FOUND</div>,
      },
    ],
  },
  {
    path: "/test", // test route
    element: <TestPage />,
  },

  {
    path: "/SuccessPage", // test route
    element: <SuccessPage />,
  },
  {
    path: "/SignUpSuccess",
    element: <SignUpSuccess />,
  },
  {
    path: "*", // test route
    element: <div>NOT FOUND</div>,
  },
];
