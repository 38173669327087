import { DateRangePickerProps } from "@mui/lab";
import { AppInputSwitchProps } from "../AppInputSwitcher/AppInputSwitcher.props";

export interface WebCheckInCombinedForm {
  zip: AppInputSwitchProps;
  lastName: AppInputSwitchProps;
  country: AppInputSwitchProps;
  gender: AppInputSwitchProps;
  isSignatoryTheMainDriver: AppInputSwitchProps;
  mobileNumber: AppInputSwitchProps;
  phNum1: AppInputSwitchProps;
  birthDate: AppInputSwitchProps;
  n: AppInputSwitchProps;
  //N: AppInputSwitchProps;
  birthProv: AppInputSwitchProps;
  ssn: AppInputSwitchProps;
  firstName: AppInputSwitchProps;
  birthPlace: AppInputSwitchProps;
  streetName: AppInputSwitchProps;
  middleName: AppInputSwitchProps;
  birthNation: AppInputSwitchProps;
  vatNumber: AppInputSwitchProps;
  companyName: AppInputSwitchProps;
  city: AppInputSwitchProps;
  state: AppInputSwitchProps;
  voucherCode: AppInputSwitchProps;
  documents: AppInputSwitchProps;
  documentNumber: AppInputSwitchProps;
  licenseType: AppInputSwitchProps;
  issuedBy: AppInputSwitchProps;
  releaseDate: AppInputSwitchProps;
  expiryDate: AppInputSwitchProps;
  email: AppInputSwitchProps;
  streetNumber: AppInputSwitchProps;
  CompanyEInvoicingEmail: AppInputSwitchProps;
  CompanyEInvoicingCode: AppInputSwitchProps;
  taxCode: AppInputSwitchProps;
}

/**
 * @NOTE: Some of the keys are appended in BookingAvailableApi.ts file
 *
 */

const Valid18Year = new Date();
Valid18Year.setFullYear(Valid18Year.getFullYear() - 18);

const Valid75Year = new Date();
Valid75Year.setFullYear(Valid75Year.getFullYear() - 75);
export const WebCheckInFormFields: WebCheckInCombinedForm = {
  firstName: {
    type: "text",
    label: "First Name",
    key: "firstName",
    placeholder: "First Name",
    required: true,
    options: [],
  },
  email: {
    type: "email",
    label: "Email",
    key: "email",
    placeholder: "Email",
    required: true,
    options: [],
  },
  CompanyEInvoicingEmail: {
    type: "email",
    label: "SDIEmail",
    key: "CompanyEInvoicingEmail",
    placeholder: "CompanyEInvoicingEmail",
    required: true,
    options: [],
  },
  CompanyEInvoicingCode: {
    type: "text",
    label: "SDI Code",
    key: "CompanyEInvoicingCode",
    placeholder: "CompanyEInvoicingCode",
    //readOnly: false,
    options: [],
  },
  middleName: {
    type: "text",
    label: "Middle Name",
    key: "middleName",
    placeholder: "Middle Name",
    //readOnly: false,
    options: [],
  },
  lastName: {
    type: "text",
    label: "Last Name",
    key: "lastName",
    placeholder: "Last Name",
    required: true,
    options: [],
  },
  mobileNumber: {
    type: "mobile",
    label: "Mobile Number",
    key: "telephone",
    placeholder: "Mobile Number",
    required: true,
    options: [],
  },
  phNum1: {
    type: "mobile",
    label: "Mobile Number",
    key: "phNum1",
    placeholder: "Mobile Number",
    required: true,
    options: [],
  },
  country: {
    type: "select",
    variant: "with-type",
    label: "Country",
    key: "country",
    placeholder: "Country Name",
    required: true,
    options: [],
  },
  city: {
    type: "select",
    variant: "with-search",
    label: "City",
    key: "city",
    placeholder: "City Name",
    required: true,
    options: [],
  },
  state: {
    type: "select",
    variant: "with-search",
    label: "State",
    key: "state",
    placeholder: "State",
    required: true,
    options: [],
  },
  zip: {
    type: "select",
    variant: "with-search",
    label: "ZIP Code",
    key: "zip",
    placeholder: "ZIP Code",
    required: true,
    options: [],
  },
  streetName: {
    type: "text",
    variant: "material",
    label: "Street Name (Home Address)",
    key: "streetName",
    placeholder: "Street Name",
    required: true,
    options: [],
  },
  n: {
    type: "text",
    label: "N",
    key: "streetNumber",
    placeholder: "N",
    required: true,
    options: [],
  },
  vatNumber: {
    type: "text",
    label: "VAT number",
    key: "vatNumber",
    placeholder: "VAT number",
    required: true,
    options: [],
  },
  gender: {
    type: "radio",
    label: "Gender",
    key: "sex",
    options: ["MALE", "FEMALE"],
    required: true,
  },
  ssn: {
    type: "text",
    label: "SSN",
    key: "ssnNumber",
    placeholder: "SSN",
    required: true,
    readOnly: false,
    options: [],
  },
  birthNation: {
    type: "select",
    variant: "with-search",
    label: "Birth Nation",
    key: "birthNation",
    placeholder: "Birth Nation",
    required: true,
    options: [],
    shrink: true,
  },
  birthDate: {
    type: "date",
    label: "Birth Date",
    variant: "with-search",
    key: "dateOfBirth",
    placeholder: "Birth Date",
    required: true,
    options: [],
    maxDate: Valid18Year,
    minDate: Valid75Year,
  },
  birthProv: {
    type: "text",
    label: "Birth Prov",
    key: "birthProvince",
    placeholder: "Birth Prov",
    required: true,
    options: [],
  },
  birthPlace: {
    type: "select",
    variant: "with-search",
    label: "Birth Place",
    key: "birthPlace",
    placeholder: "Birth Place",
    required: true,
    options: [],
    shrink: true,
  },

  companyName: {
    type: "text",
    label: "Company Name",
    key: "companyName",
    placeholder: "Company Name",
    options: ["YES", "NO"],
    required: true,
  },
  isSignatoryTheMainDriver: {
    type: "radio",
    variant: "standard",
    label: "Is the signatory of the rental agreement also the main driver ?",
    key: "isDriver",
    placeholder: "Company Name",
    options: ["YES", "NO"],
    //  required: true,
  },
  voucherCode: {
    type: "text",
    label: "Voucher Code",
    key: "reservationVoucher",
    placeholder: "Voucher Code",
    required: true,
    readOnly: false,
    options: [],
  },
  documents: {
    label: "Documents",
    key: "document",
    placeholder: "Documents",
    type: "text",
    variant: "standard",
    required: true,
    options: [],
  },
  documentNumber: {
    label: "Document Number",
    key: "documentNumber",
    placeholder: "Document Number",
    type: "text",
    variant: "standard",
    required: true,
    options: [],
  },
  licenseType: {
    label: "Licence Type",
    key: "licenseType",
    placeholder: "Licence Type",
    type: "text",
    variant: "standard",
    required: true,
    options: [],
  },
  issuedBy: {
    label: "Issued By",
    key: "issuedBy", // have different keys for userDetails  and driver (v.i.z issuedBy and issue by respectively
    placeholder: "Issued By",
    type: "text",
    variant: "standard",
    // required: true,
    options: [],
  },
  releaseDate: {
    label: "Release Date",
    key: "releaseDate",
    placeholder: "Release Date",
    type: "date",
    variant: "standard",
    required: true,
    options: [],
    maxDate: new Date(),
  },
  expiryDate: {
    label: "Expiry Date",
    key: "documentExpiryDate",
    placeholder: "Expiry Date",
    type: "date",
    variant: "standard",
    required: true,
    options: [],
    minDate: new Date(),
  },
  streetNumber: {
    label: "Street Number",
    key: "street number",
    placeholder: "Street Number",
    type: "text",
    variant: "standard",
    required: false,
    options: [],
  },
  taxCode: {
    label: "Tax Code",
    key: "taxCode",
    placeholder: "Tax Code",
    type: "text",
    variant: "standard",
    required: false,
    options: [],
  },
};
export const ReadOnlyIfInitialValuesNotProvided: string[] = [
  //WebCheckInFormFields.mobileNumber.key,
  WebCheckInFormFields.companyName.key,
  //WebCheckInFormFields.email.key,
  WebCheckInFormFields.firstName.key,
  WebCheckInFormFields.lastName.key,
  // WebCheckInFormFields.middleName.key,
  // WebCheckInFormFields.country.key,
  // WebCheckInFormFields.city.key,
  // WebCheckInFormFields.state.key,
  // WebCheckInFormFields.zip.key,
  // WebCheckInFormFields.streetName.key,
  //WebCheckInFormFields.n.key,
  WebCheckInFormFields.vatNumber.key,
  WebCheckInFormFields.gender.key,
  WebCheckInFormFields.ssn.key,
  WebCheckInFormFields.birthNation.key,
  WebCheckInFormFields.birthDate.key,
  WebCheckInFormFields.birthProv.key,
  WebCheckInFormFields.birthPlace.key,
  WebCheckInFormFields.isSignatoryTheMainDriver.key,
  WebCheckInFormFields.documents.key,
  WebCheckInFormFields.documentNumber.key,
  WebCheckInFormFields.licenseType.key,
  WebCheckInFormFields.issuedBy.key,
];
export const CompanyFormFields = {
  companyName: WebCheckInFormFields.companyName,
  email: WebCheckInFormFields.email,
  mobileNumber: WebCheckInFormFields.mobileNumber,
  country: WebCheckInFormFields.country,
  city: WebCheckInFormFields.city,
  state: WebCheckInFormFields.state,
  zip: WebCheckInFormFields.zip,
  streetName: WebCheckInFormFields.streetName,
  n: WebCheckInFormFields.n,
  vatNumber: WebCheckInFormFields.vatNumber,
  gender: WebCheckInFormFields.gender,
};
export const IndividualFormFields = {
  firstName: WebCheckInFormFields.firstName,
  middleName: WebCheckInFormFields.middleName,
  lastName: WebCheckInFormFields.lastName,
  email: WebCheckInFormFields.email,
  mobileNumber: WebCheckInFormFields.phNum1,
  country: WebCheckInFormFields.country,
  city: WebCheckInFormFields.city,
  state: WebCheckInFormFields.state,
  zip: WebCheckInFormFields.zip,
  streetName: WebCheckInFormFields.streetName,
  n: WebCheckInFormFields.n,
  gender: WebCheckInFormFields.gender,
  ssn: WebCheckInFormFields.ssn,
  birthNation: WebCheckInFormFields.birthNation,
  birthDate: WebCheckInFormFields.birthDate,
  birthProv: WebCheckInFormFields.birthProv,
  birthPlace: WebCheckInFormFields.birthPlace,
  // releaseDate: WebCheckInFormFields.releaseDate,
  // isSignatoryTheMainDriver: WebCheckInFormFields.isSignatoryTheMainDriver,
  // documents: WebCheckInFormFields.documents,
  // documentNumber: WebCheckInFormFields.documentNumber,
  // licencetype: WebCheckInFormFields.licenseType,
  // issuedBy: WebCheckInFormFields.issuedBy,
};
export const SoleTraderFormFields = {
  companyName: WebCheckInFormFields.companyName,
  firstName: WebCheckInFormFields.firstName,
  middleName: WebCheckInFormFields.middleName,
  lastName: WebCheckInFormFields.lastName,
  email: WebCheckInFormFields.email,
  mobileNumber: WebCheckInFormFields.mobileNumber,
  country: WebCheckInFormFields.country,
  city: WebCheckInFormFields.city,
  state: WebCheckInFormFields.state,
  zip: WebCheckInFormFields.zip,
  vatNumber: WebCheckInFormFields.vatNumber,
  streetName: WebCheckInFormFields.streetName,
  n: WebCheckInFormFields.n,
  gender: WebCheckInFormFields.gender,
  ssn: WebCheckInFormFields.ssn,
  birthNation: WebCheckInFormFields.birthNation,
  birthDate: WebCheckInFormFields.birthDate,
  birthProv: WebCheckInFormFields.birthProv,
  birthPlace: WebCheckInFormFields.birthPlace,
  isSignatoryTheMainDriver: WebCheckInFormFields.isSignatoryTheMainDriver,
};

export const CompanyInvoiceFormFields = {
  IndividualFirstName: WebCheckInFormFields.firstName,
  IndividualLastName: WebCheckInFormFields.lastName,
  CompanyName: WebCheckInFormFields.companyName,
  CompanyPhoneNumber: WebCheckInFormFields.mobileNumber,
  CompanyEmail: WebCheckInFormFields.email,
  country: WebCheckInFormFields.country,
  city: WebCheckInFormFields.city,
  CompanyBirthDate: WebCheckInFormFields.birthDate,
  birthNation: WebCheckInFormFields.birthNation,

  birthPlace: WebCheckInFormFields.birthPlace,
  CompanyStreet: WebCheckInFormFields.streetName,
  CompanyStreetNumber: WebCheckInFormFields.streetNumber,
  CompanyBirthProv: WebCheckInFormFields.birthProv,

  CompanyEInvoicingEmail: WebCheckInFormFields.CompanyEInvoicingEmail,
  CompanyEInvoicingCode: WebCheckInFormFields.CompanyEInvoicingCode,
  CompanyPostalCode: WebCheckInFormFields.zip,
  CompanyVatNumber: WebCheckInFormFields.vatNumber,
  CompanyTaxCode: WebCheckInFormFields.taxCode,
  IndividualGender: WebCheckInFormFields.gender,
  // ...IndividualFormFields,
};
