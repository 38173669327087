import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { BookingApi } from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import InvoiceCard from "./InvoiceCard";
import { t } from "i18next";

export default function MyInvoices() {
  const [showInvoices, setShowInvoices] = useState(true);
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowInvoices(e.reservedArea);
    });
  }, [showInvoices]);
  return (
    <div>
      {showInvoices ? (
        <div className="flex">
          <BookingDetalisListSideNavigation />
          <div className="pt-12">
            <div className="container mx-12">
              <div className="flex flex-col">
                <div className="flex justify-between space-x-2 mb-8">
                  <div>
                    <h1 className="text-[28px] font-medium leading-snug text-[#232323]">
                      {t("Invoice List")}
                    </h1>
                    <h4 className="text-[#838181] font-normal text-[20px]">
                      {t("View all your Invoices here.")}
                    </h4>
                  </div>
                </div>
                <div className={"block"}>
                  <InvoiceCard></InvoiceCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
