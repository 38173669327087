export default {
  isAvailable: "self service",
  bookingDetails: { id: "" },
  vehicleImages: {
    front: "",
    back: "",
    left: "",
    right: "",
    interiors: [],
  },
  vehicleDetials: {},
  vehicleLocation: {},
  vehicleCurrentValues: {},
};
