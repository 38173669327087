import {
  DefaultValuesDetailsForm,
  IndividualFormProps,
} from "../../Layouts/WebCheckInForms/WebCheckInForms.props";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppInputSwitchProps } from "../../Layouts/AppInputSwitcher/AppInputSwitcher.props";
import {
  CompanyFormFields,
  ReadOnlyIfInitialValuesNotProvided,
} from "../../Layouts/WebCheckInForms/FormOptions";
import { AppInputSwitcher } from "../../Layouts/AppInputSwitcher/AppInputSwitcher";
import { useWebCheckInForm } from "../../../hooks/WebCheckInForm";
import { useForm } from "react-hook-form";
import { FormFieldErrorMessage } from "../FormFieldErrorMessage/FormFieldErrorMessage";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

export function CompanyForm({
  values = { ...DefaultValuesDetailsForm },
  initialValues = { ...DefaultValuesDetailsForm },
  onChange = () => {},
  children = <></>,
  onSubmit = () => {},
}: IndividualFormProps) {
  const [companyFormValues, setCompanyFormValues, companyFormFields] =
    useWebCheckInForm(CompanyFormFields, {
      ...values,
    });
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    defaultValues: { ...values },
  });
  const { t: translate } = useTranslation();

  useEffect(() => {
    Object.keys(values).map((e) => {
      setValue(e, companyFormValues[e]);
    });
  }, [companyFormValues]);
  return (
    <form
      className={"flex flex-col gap-4"}
      onSubmit={handleSubmit((e) => {
        //   console.log(e);
        onSubmit(e);
      })}
    >
      {Object.values(companyFormFields).map((el: AppInputSwitchProps) => {
        const readOnly =
          el.readOnly ||
          (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
            !!getValues(el.key));
        const options: RegisterOptions = {
          required: {
            value: !!el.required,
            message: "This is a required field",
          },
        };
        if (el.type === "email") {
          options.pattern = {
            value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
            message: "Please provide a valid email",
          };
        }
        return (
          <div key={el.key}>
            <AppInputSwitcher
              {...el}
              {...register(el.key, {
                ...options,
              })}
              onChange={() => {}}
              className="h-[4.3rem]"
              readOnly={readOnly}
              label={translate(el.label || "")}
              required={false}
              placeholder={translate(el.placeholder || "")}
              value={getValues(el.key) || ""}
              onchange={async (e) => {
                const tempValues = {
                  ...values,
                  [el.key]: e,
                };
                setValue(el.key, e);
                setCompanyFormValues({ ...tempValues });
                onChange({
                  ...tempValues,
                });
              }}
            />
            <FormFieldErrorMessage>
              {errors[el.key]?.message}
            </FormFieldErrorMessage>
          </div>
        );
      })}
      {children}
    </form>
  );
}
