import * as AllIcons from "@mui/icons-material";
import { SvgIconComponent } from "@mui/icons-material";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import React from "react";

import { BookingGetters } from "../../../store/onlineBooking/getters";
import { SelectedVehicleExtraOptions } from "../../../@types/VehicleOptions";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export function ReservationDetailsPreview(): JSX.Element {
  const ReservationDetails = BookingGetters.getBookingDetails();
  const { t: translate } = useTranslation();
  const { vehicleDetails, paymentDetails } =
    BookingGetters.getSelectedVehicleDetails();
  const UserDetails = BookingGetters.getUserDetails();
  const currency = "EUR";
  function DetailsCard({
    primary,
    secondary,
    ImageIcon,
  }: {
    primary: string;
    secondary: string;
    ImageIcon?: SvgIconComponent;
  }) {
    return (
      <ListItem style={{ width: "fit-content" }}>
        {ImageIcon && (
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "transparent" }}>
              <ImageIcon color="primary" />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
  }
  const isSmallScreen = window.innerWidth <= 768;
  const amountStyle = {
    fontSize: isSmallScreen ? "1.2rem" : "2rem",
  };
  return (
    <div>
      <div
        className="box-border flex flex-row items-center justify-center"
        style={amountStyle}
      >
        <h2 className="font-semibold grow text-right box-border pr-8 text-[#202020]">
          {t("Total Amount")} :
        </h2>
        <div className=" text-red-500 font-bold grow text-left">
          {
            formatPriceWithCurrencySymbol(currency, paymentDetails?.price || 0)
              .original
          }
        </div>
      </div>
      <List dense>
        <ListSubheader
          style={isSmallScreen ? { fontSize: "1rem" } : { fontSize: "1.2rem" }}
        >
          {t("Reservation Details")}
        </ListSubheader>
        <div className="grid md:grid-cols-2 md:gap-2 lg:grid-cols-3 lg:gap-4 grid-cols-1 gap-0">
          <DetailsCard
            ImageIcon={AllIcons.Today}
            primary={t("Pick up Date and Time")}
            secondary={
              new Date(ReservationDetails?.pickUpDateAndTime).toString() ||
              "N/A"
            }
          />
          <DetailsCard
            ImageIcon={AllIcons.LocationOn}
            primary={t("Pick up location name")}
            secondary={
              ReservationDetails?.pickUpLocationDetails?.name +
              " " +
              ReservationDetails?.pickUpLocationDetails?.locationNumber +
              " " +
              ReservationDetails?.pickUpLocationDetails?.locationAddress +
              " " +
              ReservationDetails?.pickUpLocationDetails?.locationCity +
              " " +
              ReservationDetails?.pickUpLocationDetails?.zipCode +
              "-" +
              ReservationDetails?.pickUpLocationDetails?.code
            }
          />
          <DetailsCard
            ImageIcon={AllIcons.Today}
            primary={t("Drop off Date and Time")}
            secondary={
              new Date(ReservationDetails?.dropOffDateAndTime).toString() ||
              "N/A"
            }
          />
          <DetailsCard
            ImageIcon={AllIcons.LocationOn}
            primary={t("Drop off Location Name")}
            secondary={
              ReservationDetails?.dropOffLocationDetails?.name +
              " " +
              ReservationDetails?.dropOffLocationDetails?.locationNumber +
              " " +
              ReservationDetails?.dropOffLocationDetails?.locationAddress +
              " " +
              ReservationDetails?.dropOffLocationDetails?.locationCity +
              " " +
              ReservationDetails?.dropOffLocationDetails?.zipCode +
              "-" +
              ReservationDetails?.dropOffLocationDetails?.code
            }
          />
          <DetailsCard
            ImageIcon={AllIcons.AccountCircle}
            primary={t("Vehicle Details")}
            secondary={vehicleDetails?.Vehicle?.VehMakeModel?.Name || "N/A"}
          />
        </div>
      </List>
      <List>
        <ListSubheader style={{ fontSize: "1.2rem" }}>
          {t("Addons Details")}
        </ListSubheader>
        <div className="grid md:grid-cols-2 md:gap-2 lg:grid-cols-4 grid-cols-1 gap-0">
          {Object.values<SelectedVehicleExtraOptions>(
            paymentDetails?.addedExtraOptions || []
          ).length ? (
            Object.values<SelectedVehicleExtraOptions>(
              paymentDetails?.addedExtraOptions || []
            ).map((e: SelectedVehicleExtraOptions, index) => {
              const total = e.price * e.quantity;
              return (
                <DetailsCard
                  key={index}
                  primary={e.name}
                  secondary={
                    formatPriceWithCurrencySymbol(currency, e.price).original +
                    " x " +
                    e.quantity +
                    "(U) = " +
                    formatPriceWithCurrencySymbol(currency, total).original
                  }
                />
              );
            })
          ) : (
            <ListItem>
              <p className="text-[#202020]"> N/A</p>
            </ListItem>
          )}
        </div>
      </List>
    </div>
  );
}
