import * as Flags from "country-flag-icons/react/3x2";
import * as React from "react";
import { ForwardedRef, forwardRef, SyntheticEvent } from "react";

import {
  AppAutoCompleteProps,
  AppFlagSelectProps,
  AppSelectProps,
} from "./AppSelect.props";
import {
  Autocomplete,
  createFilterOptions,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { KeyboardArrowDown, RemoveRedEye } from "@mui/icons-material";
import { useSelectOptionsStyles as useSelectFlagOptionsStyles } from "./AppSelect.styles";
import { OptionsI, SingleOptionType } from "../../../@types/FormFields";

let rounded = {
  borderRadius: "3rem",
  borderColor: "white",
  borderWidth: "0.1rem",
};
let defaultStyle = {
  borderRadius: "0.2rem",
};

function setSelectStyles(styles) {
  defaultStyle = {
    ...defaultStyle,
    ...styles,
  };
  rounded = {
    ...rounded,
    ...styles,
  };
}
export { AppDropDownSelect as default };
export const AppDropDownSelect = forwardRef(function AppDropDownSelect(
  {
    label,
    required = false,
    selectStyles = {},
    className = "",
    value = "",
    onChange = () => {},
    options = [],
    rounded: isRounded,
    readOnly = false,
  }: AppSelectProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  setSelectStyles(selectStyles);
  const SelectStyleClasses = useSelectFlagOptionsStyles();

  return (
    <div
      className={`w-full h-full flex flex-row gap-2 items-center justify-center  ${className} ${SelectStyleClasses.standard}`}
    >
      <div>
        <InputLabel
          className="w-full whitespace-nowrap "
          style={{ overflow: "visible" }}
        >
          <Typography>
            {label}
            {required && <span className="text-red-600"> *</span>}
          </Typography>
        </InputLabel>
      </div>
      <div className={"w-full flex flex-col"}>
        <Select
          ref={ref}
          disabled={readOnly}
          displayEmpty
          value={value}
          onChange={(e) => {
            onChange(e.target.value, e);
          }}
          sx={isRounded ? { ...rounded } : { ...defaultStyle }}
          input={<OutlinedInput required={required} />}
        >
          <MenuItem value={""} disabled={true}>
            <div className="flex flex-row w-full h-full flex items-center box-border pl-2 text-gray-500">
              {/* Select */}
              Selezionare
            </div>
          </MenuItem>
          {options.map((el, index) => {
            let label: string = el.toString();
            let value: undefined | any = el.toString();
            if (typeof el != "string") {
              label = el.label;
              value = el.value;
            }
            return (
              <MenuItem value={value} key={index}>
                <div className="flex flex-row w-full h-full flex items-center justify-center box-border pl-2">
                  {<div>{label}</div>}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
});

export const AppDropDownWithSearch = forwardRef(function AppDropDownWithSearch(
  {
    label,
    required = false,
    className = "",
    value = "",
    onChange = () => {},
    options = [],
    readOnly = false,
    onInputChange = () => {},
    ...props
  }: AppAutoCompleteProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const filterOptions = createFilterOptions({
    limit: 50,
    matchFrom: "start",
  });
  const [isShowingOptions, setIsShowingOptions] = React.useState(false);
  return (
    <div className={`w-full ${className}`}>
      {
        <Autocomplete
          ref={ref}
          disableClearable
          disabled={readOnly || props.disabled}
          value={value || ""}
          options={options.sort()}
          open={isShowingOptions}
          renderOption={(
            e: React.HTMLAttributes<HTMLLIElement>,
            option,
            state
          ) => {
            const currentOption = option as SingleOptionType;
            let label: string = currentOption.toString();
            let value: any = currentOption.toString();
            let icon: undefined | JSX.Element;
            if (typeof currentOption != "string") {
              label = currentOption.label;
              value = currentOption.value;
              icon = currentOption.icon;
            }
            return (
              <div
                className="box-border py-2 pl-2 cursor-pointer flex flex-nowrap items-center"
                key={value}
                onClick={(e) => {
                  onChange(value as string, e);
                  setIsShowingOptions(false);
                }}
              >
                {icon && (
                  <InputAdornment position="start">{icon}</InputAdornment>
                )}
                {label}
              </div>
            );
          }}
          onChange={(e, value) => {
            onChange(value as string, e);
          }}
          filterOptions={filterOptions}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                onChange={() => {
                  if (!isShowingOptions) setIsShowingOptions(!isShowingOptions);
                }}
                onBlur={() => {
                  setIsShowingOptions(false);
                }}
                onClick={() => {
                  setIsShowingOptions(!isShowingOptions);
                }}
                disabled={props.disabled || readOnly}
                label={label}
                required={required}
              />
            );
          }}
          onInputChange={(e: SyntheticEvent) => {
            if (!e) {
              return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onInputChange(e.target.value || "");
          }}
          fullWidth
        />
      }
    </div>
  );
});
AppFlagSelect.propTypes = AppFlagSelectProps;
AppFlagSelect.defaultProps = {
  selectStyles: {},
  className: "'",
  dropDownIcon: <KeyboardArrowDown />,
  onChange: () => {},
};

export function AppFlagSelect({
  options,
  value,
  selectStyles,
  className,
  dropDownIcon,
  onChange,
}) {
  const SelectStyles = useSelectFlagOptionsStyles(selectStyles);
  return (
    <div className={"w-full h-full" + " " + className}>
      <Select
        IconComponent={() => dropDownIcon}
        fullWidth
        className={SelectStyles.flagged}
        value={value}
        onChange={(e: SelectChangeEvent) => {
          onChange(e.target.value, e || "");
        }}
      >
        {options.map((countryCode, index) => {
          return (
            <MenuItem className="w-16" value={countryCode} key={index}>
              {getFlagComponent(countryCode)}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}

export function getFlagComponent(countryCode) {
  const Flag = Flags[countryCode.toString().toUpperCase()];
  return Flag && <Flag className="max-w-4 max-h-4 " />;
}
