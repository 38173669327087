import { configureStore } from "@reduxjs/toolkit";
import BookingReducers from "./onlineBooking/index";
import selfService from "./selfService";
import webCheckIn from "./webCheckIn";
import PageConfig from "./PageConfigurations";
import { EnhancedStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
export const Store: EnhancedStore = configureStore({
  reducer: {
    booking: BookingReducers,
    selfService: selfService,
    webCheckIn: webCheckIn,
    pageConfig: PageConfig,
  },
});
export type AppDispatch = typeof Store.dispatch;
export const useAppDispatch = () => useDispatch();
export { Store as default };
