import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import states from "./states";
const WebCheckInSlice = createSlice({
  name: "WebCheckIn",
  initialState: states,
  reducers: reducers,
});
export const WebCheckInActions = WebCheckInSlice.actions;
export default WebCheckInSlice.reducer;
