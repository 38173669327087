import { LoadingButton } from "@mui/lab";
import { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookingApi from "../../API/Apis/BookingApi";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import ReservationCard from "../../components/Layouts/ReservationsCard/ReservationsCards";
import { AppStandardButton } from "../../components/UI/AppButtons";
import NotAuthorisedPage from "../notAuthorisedPage";
import BookingCard from "./BookingCard";

export default function MyReservationsPage(props) {
  const [showReserve, setShowReserve] = useState(false);
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowReserve(e.reservedArea);
    });
  }, [showReserve]);

  return (
    <div>
      <AppStandardButton
        href="/"
        className={"flex justify-end mr-1"}
        sx={{
          width: 200,
          "& .css-9pkl4c-MuiButtonBase-root-MuiButton-root": {
            textAlign: "end",
          },
        }}
        variant="contained"
        color="chooseVehiclePayNowButton"
        style={{ borderRadius: "5" }}
      >
        {/* Go to Account */}
        <span className="normal-case text-lg"> Book a Car</span>
      </AppStandardButton>
      <div className="flex mt-12 mr-6 h-480 max-h-screen ">
        <BookingDetalisListSideNavigation />
        {showReserve ? (
          <div>
            <div className="container  mx-4">
              <div className="flex flex-col   ">
                <div className="flex justify-between space-x-2 mb-8  ">
                  <div>
                    <h1 className="font-medium text-[36px] text-[#232323]">
                      Booking Details
                    </h1>
                    <h4 className="text-[#838181] font-normal text-[20px]">
                      Here is your booking details
                    </h4>
                  </div>
                </div>
                <div>
                  <BookingCard></BookingCard>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NotAuthorisedPage />
        )}
      </div>
    </div>
  );
}
