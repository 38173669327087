import { CreateNewBooking } from "../../../@types/DTO/Booking";
import { CustomerDetailsResponse_2 } from "../../../@types/DTO/ApiResponses";
import { ReactNode } from "react";

export interface GetReservationByReservationNumber {
  onChange?: (data) => void | null;
  values?: DetailsFormValues;
  onSubmit?: (e: DetailsFormValues) => void;
  children?: ReactNode;
}

export interface GetReservationByReservationVoucher {
  onChange?: (data) => void | null;
  values?: Record<string, string>;
  onSubmit?: (e: Record<any, any>) => void;
  children?: ReactNode;
}

export interface DetailsFormValues
  extends Omit<CustomerDetailsResponse_2, "isDriver"> {
  isDriver: "YES" | "NO";
  reservationDate: Date | null;
  reservationNumber: string;
  reservationPrefix: string;
  reservationVoucher: string;
  documents: File[];
}

export interface IndividualFormProps {
  onSubmit?: (data) => void | null | Promise<void>;
  onChange?: (data: Record<any, any>) => void | Promise<void>;
  values?: Record<string, any>;
  initialValues?: DetailsFormValues;
  children?: ReactNode;
}

export const DefaultValuesDetailsForm: DetailsFormValues = {
  document: "",
  documents: [],
  birthProvince: "",
  customerId: "",
  customerType: "INDIVIDUAL",
  reservationDate: null,
  reservationNumber: "",
  reservationPrefix: "",
  reservationVoucher: "",
  birthNation: "",
  birthPlace: "",
  city: "",
  companyName: "",
  country: "",
  dateOfBirth: "",
  documentExpiryDate: "",
  documentIssueDate: "",
  documentNumber: "",
  email: "",
  firstName: "",
  isDriver: "NO",
  issuedBy: "",
  lastName: "",
  license: "",
  licenseType: "",
  mobileNumber: "",
  sex: "",
  ssnNumber: "",
  state: "",
  streetName: "",
  streetNumber: "",
  telephone: "",
  vatNumber: "",
  zip: "",
};
export const BookingDetailsDefaultValues: CreateNewBooking = {
  customer: "",
  customerFName: "",
  customerId: 0,
  middleName: "",
  customerLName: "",
  data: {},
  driver1: "",
  driver1FName: "",
  driver1Id: 0,
  driver1LName: "",
  driver2: "",
  driver2FName: "",
  driver2Id: 0,
  driver2LName: "",
  driver3: "",
  driver3FName: "",
  driver3Id: 0,
  driver3LName: "",
  dropOffDate: "",
  dropOffLocation: "",
  dropOffTime: undefined,
  errorMessage: "",
  isCustIndividualCompany: false,
  isCustPhysicalPerson: false,
  isD1IndividualCompany: false,
  isD1PhysicalPerson: false,
  isD2IndividualCompany: false,
  isD2PhysicalPerson: false,
  isD3IndividualCompany: false,
  isD3PhysicalPerson: false,
  numPrefCode: "",
  pickUpDate: "",
  pickUpLocation: "",
  pickUpTime: "",
  privacyMessage1: "",
  privacyMessage2: "",
  reservationId: 0,
  reservationSourceCode: "",
  reservationSourceId: 0,
  rezIsAccepted: false,
  rezIsCancelled: false,
  rezIsConfirmed: false,
  rezIsNoShow: false,
  rezIsOnRequest: false,
  rezIsRefused: false,
  rezIsRental: false,
  status: "success",
  voucher: "",
  voucherDays: 0,
  voucherPrepaid: false,
};
