import React, { useEffect } from "react";
import { t } from "i18next";
import AppStandardButton from "../../components/UI/AppButtons";

export function BookingConfirmationPayPal() {
  return (
    <section className="min-h-[80vh] box-border px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header
        className="grow flex flex-col items-center justif-center gap-4 box-border py-8"
        style={{ backgroundColor: "white" }}
      >
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1 className="text-3xl font-bold text-[#000000] py-2">
          {t("Congratulations! Your Booking Is Completed")}
        </h1>
        <p className="font-bold text-black-600"></p>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-8"}
            variant="contained"
            color="white"
            style={{ borderRadius: "5" }}
          >
            <span className="normal-case text-[#2267ae] p-1 text-lg">
              {" "}
              Homepage
            </span>
          </AppStandardButton>
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-8"}
            color="white"
            variant="contained"
            style={{ borderRadius: "5" }}
          >
            <span className="normal-case text-[#2267ae] p-1 text-lg">
              {" "}
              Dashboard
            </span>
          </AppStandardButton>
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
