import React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function BookingCard() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            id="bookingNumber"
            label="Booking Number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField required fullWidth id="clientName" label="Client Name" />
        </Grid>
        {/*</Grid>*/}
        {/*<Grid container spacing={2}>*/}
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            id="pickuploc"
            label="Pickup Location"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            id="returnloc"
            label="Return Location"
          />
        </Grid>
        {/*</Grid>*/}
        {/*<Grid container spacing={2}>*/}
        <Grid item xs={12} sm={4}>
          <TextField required fullWidth id="startdate" label="Start Date" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField required fullWidth id="endDate" label="EndDate" />
        </Grid>
        {/*</Grid>*/}
        {/*<Grid container spacing={2}>*/}
        <Grid item xs={12} sm={4}>
          <TextField required fullWidth id="companyName" label="Company Name" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField required fullWidth id="vehicle" label="Vehicle" />
        </Grid>
        {/*</Grid>*/}
        {/*<Grid container spacing={2}>*/}
        <Grid item xs={12} sm={4}>
          <TextField required fullWidth id="totalAmount" label="Total Amount" />
        </Grid>
      </Grid>
    </>
  );
}
