import { makeStyles } from "@mui/styles";
import CSS from "csstype";
export const useCardStyles = makeStyles(() => ({
  standard(props: CSS.Properties) {
    return {
      "&.MuiPaper-root ": {
        borderRadius: props.borderRadius || "0.5rem",
        height: props.height || "100%",
        width: "100%",
      },
    };
  },
}));
