import React from "react";
import AccountInformation from "../../components/Layouts/AccountInformation";

function HomePage() {
  return (
    <div className="min-h-screen">
      <div className="border-box px-4 flex-col flex  bg-gray-100 h-full">
        <header className="border-box p-4">
          {/* <h2 className="text-blue-600 font-bold text-4xl ">My Account</h2> */}
          <h2 className="text-blue-600 font-bold text-4xl ">Il mio account</h2>
        </header>
        <main className="grow border-box p-4rounded-3xl flex flex-col items-center contents-center">
          <section className="flex-col contents-center items-center w-full">
            <AccountInformation />
          </section>
        </main>
      </div>
    </div>
  );
}
export default HomePage;
