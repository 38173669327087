export function setTime(time: string, date: Date = new Date()) {
  const newDate = new Date(date.toString());
  const [hours, minutes, seconds] = time.split(":");
  newDate.setHours(parseInt(hours) || 0);
  newDate.setMinutes(parseInt(minutes) || 0);
  newDate.setSeconds(parseInt(seconds) || 0);

  return newDate;
}

export function setEndTime(time: string, date: Date = new Date()) {
  const newDate = new Date(date.toString());
  const [hours, minutes, seconds] = time.split(":");
  newDate.setHours(parseInt(hours) || 0);
  newDate.setMinutes(parseInt(minutes) + 1 || 0);
  newDate.setSeconds(parseInt(seconds) || 0);

  return newDate;
}
