import { makeStyles } from "@mui/styles";

export const useSelectOptionsStyles = makeStyles(() => ({
  flagged(stylesOverriding) {
    return {
      "&.MuiOutlinedInput-root": {
        height: "100%",
        ...stylesOverriding,
        cursor: "pointer",
      },
      "& .MuiSelect-select": {
        boxSizing: "content-box",
        padding: "5.5px 8px !important",
        display: "flex",
        alignItems: "center",
      },
      "& .MuiSvgIcon-root": {
        flexShrink: 1,
        position: "unset",
        fontSize: "2.5rem",
      },
    };
  },
  standard() {
    return {
      "& .MuiInputLabel-root": {
        transform: "unset",
        height: "2rem",
        width: "100%",
        maxWidth: "100%",
        padding: "0",
        margin: "0",
      },
      "& .MuiInputBase-root": {
        padding: "0.5rem",
        margin: "0",
        height: "100% ",
        width: "100% ",
        boxSizing: "border-box",
      },
      "& .MuiSelect-select": {
        padding: "0",
        margin: "0",
        height: "100% !important",
        minHeight: "100% !important",
        width: "100% !important",
        flexGrow: "1",
      },
    };
  },
}));
