import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
// import { } from "react-router-dom";

import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotAuthorisedPage() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Card sx={{ maxWidth: 345 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" color={"red"}>
            Access Denied !!!🙁
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You are not authorised to access this page. Please click on below
            button to continue in this page
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => navigate(-1)}>Go Back</Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
