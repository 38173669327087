import states, { BookingStateRedux } from "./states";

import { SliceCaseReducers } from "@reduxjs/toolkit/src";
import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";

const BookingSlice = createSlice<BookingStateRedux, typeof reducers, string>({
  name: "OnlineBooking",
  initialState: states,
  reducers: reducers,
});
export const BOOKING_ACTIONS = BookingSlice.actions;
export default BookingSlice.reducer;
