/*import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";
export function Signupform() {
  return <div className=""></div>;
}*/

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";
import { useEffect, useState } from "react";
import BookingApi from "../../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NotAuthorisedPage from "../../../views/notAuthorisedPage";
import { BookingGetters } from "../../../store/onlineBooking/getters";
import { t } from "i18next";
const App = () => {
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
};
const theme = createTheme();
interface SignInFormFields {
  email: string;
  password: string;
}

export default function SignIn() {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (data.remember) {
      localStorage.setItem("email", data.email);
      localStorage.setItem("password", data.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    BookingApi.loginUser(data).then((data) => {
      if (data.data.status == "success") {
        localStorage.setItem("userID", data.data.user.id);
        localStorage.setItem("isUserLoggedin", "true");

        navigate("/");
        localStorage.setItem(
          "onlineUserType",
          data.data.onlineUserType.description
        );
      }
      if (data.data.status != "success") {
        alert("password doest not match");
      }
    });
  };
  const appSettings = BookingGetters.getAppSettings();
  const [showReserve, setShowReserve] = useState<boolean>(
    !!appSettings?.reservedArea
  );
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowReserve(e.reservedArea);
    });
  }, [showReserve]);

  return (
    <div className="grid grid-cols-2">
      <div className="">
        <img
          src="/assets/images/reserve_area_car.jpg"
          className="w-fit h-fit aspect-square"
        />
      </div>
      <div className="flex flex-col top-0 items-center justify-center w-full h-full min-h-screen">
        <ThemeProvider theme={theme}>
          {showReserve ? (
            <div className="w-[100%]">
              {" "}
              <Container component="main" maxWidth="xs">
                {/* <Card sx={{ minWidth: 500 }}> */}
                <Grid>
                  {" "}
                  <Box
                    sx={{
                      marginTop: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      boxShadow: "none",
                    }}
                  >
                    <h1 className="text-[#3c63ae] text-3xl font-semibold mb-4">
                      {" "}
                      {t("Sign_In_to_your_account")!}
                    </h1>
                    <Box
                      component="form"
                      // onSubmit={handleSubmit}
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                      sx={{
                        "& .MuiTextField-root": { mt: 2, width: "40ch" },
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label={t("Email_Address")}
                          size="medium"
                          // name="email"
                          autoComplete="off"
                          autoFocus
                          {...register("email")}
                          error={errors.email ? true : false}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.email?.message}
                        </Typography>
                      </Grid>
                      <Grid>
                        {" "}
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          // name="password"
                          label={t("Password")}
                          type="password"
                          id="password"
                          size="medium"
                          autoComplete="off"
                          {...register("password")}
                          error={errors.password ? true : false}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.password?.message}
                        </Typography>
                      </Grid>

                      {/* <AppInputSwitcher
                  label="Password"
                  type="password"
                  key="password"
                  variant="standard"
                  onchange={() => {}}
                  value=""
                ></AppInputSwitcher> */}
                      <FormControlLabel
                        className="text-black"
                        control={
                          <Checkbox
                            // value="remember"

                            color="primary"
                            {...register("remember")}
                          />
                        }
                        label={t("Remember_me")}
                      />
                      <Grid>
                        {" "}
                        <Button
                          type="submit"
                          fullWidth
                          // className="hover:drop-shadow-lg cursor-pointer"
                          variant="contained"
                          size="large"
                          sx={{
                            mt: 3,
                            mb: 2,
                            py: 1.5,
                            bgcolor: "#3c63ae",
                            textTransform: "capitalize",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            fontSize: "16px",
                            "&:hover": {
                              background: "#3c63ae",
                            },
                          }}
                        >
                          {t("Sign_In")}
                        </Button>
                      </Grid>

                      <Grid container>
                        <Grid item xs>
                          <Link href="/forgotpassword" variant="body2">
                            <span className="text-[#004188]">
                              {t("Forgot_password")} ?
                            </span>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link href="/signup" variant="body2">
                            <span className="text-[#004188]">
                              {t("Register")}?
                            </span>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                {/* <CardContent> */}
                {/* //   <CssBaseline /> */}

                {/* </CardContent> */}
                {/* </Card> */}
              </Container>
            </div>
          ) : (
            <NotAuthorisedPage />
          )}
        </ThemeProvider>
      </div>
    </div>
  );
}
