import { makeStyles } from "@mui/styles";

export const useDatePickerStyles = makeStyles(() => ({
  root: () => ({
    "&.MuiFormControl-root": {
      border: "2px solid red",
    },
  }),
  dateAndTimePicker: () => ({}),
  dateSeparated: () => ({
    "& .MuiFormControl-root": {
      height: "100%",
    },
    "& .MuiInputBase-input": {
      padding: 0,
      paddingLeft: "0.8rem",
      height: "100%",
    },
    "& .MuiInputBase-root": {
      height: "100%",
    },
  }),
}));
