import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AppCards from "../../components/UI/AppCards";
import React, { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { Navigate, useNavigate } from "react-router-dom";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState([]);
  const [loading, setLoading] = useState(false);
  const blurScreen = () => {
    document.body.style.opacity = String(0.5);
    document.body.style.pointerEvents = "none";
  };
  useEffect(() => {
    if (loading) {
      blurScreen();
    } else {
      document.body.style.opacity = String(1);
      document.body.style.pointerEvents = "";
    }
  }, [loading]);

  return (
    <div className="flex flex-col items-center min-h-[60vh] justify-center">
      <form
        className="w-1/2"
        onSubmit={(e) => {
          setLoading(true);
          e.preventDefault();
          BookingApi.sentVerificationcode(email)
            .then((e) => {
              if (e.status == "success") {
                setLoading(false);
                alert("Email Sent Sucessfully");
                navigate("/updatePassword");
              } else {
                setLoading(false);
                alert("Email is not registered");
              }
              return e.data;
            })
            .catch((e) => {
              setLoading(false);
              return e;
            });
        }}
      >
        <AppCards className="box-border p-4">
          <header className="flex flex-col gap-2 box-border py-8">
            <h2 className="w-full  text-2xl font-semibold  text-[#3c63ae] ">
              Forgot your Password ?
            </h2>
            <p className="font-light">
              Verification Code will be sent to the registered Email address
            </p>
          </header>

          <Grid item xs={8} sm={6}>
            <AppInputSwitcher
              value={email}
              onchange={(v) => {
                setEmail(v);
              }}
              type="text"
              variant="material"
              required
              label="Email Address"
              key="email"
              options={[]}
            />
          </Grid>
          <div className="w-full flex justify-center">
            <Button
              type="submit"
              variant="outlined"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: "#3c63ae",
                color: "#FFFFFF",
                "&:hover": {
                  background: "#3c63ae",
                },
              }}
            >
              Submit
            </Button>
          </div>
        </AppCards>
      </form>
    </div>
  );
}
