import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AppCards from "../../components/UI/AppCards";
import React, { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { t } from "i18next";

export default function UpdatePassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const blurScreen = () => {
    document.body.style.opacity = String(0.5);
    document.body.style.pointerEvents = "none";
  };
  useEffect(() => {
    if (loading) {
      blurScreen();
    } else {
      document.body.style.opacity = String(1);
      document.body.style.pointerEvents = "";
    }
  }, [loading]);
  const [updateDetails, setUpdateDetails] = useState<any>([
    {
      email: "",
      verificationCode: "",
      newPassword: "",
    },
  ]);
  const handleNewPasswordChange = (event) => {
    const value = event.target.value;
    setUpdateDetails((prevState) => ({
      ...prevState,
      newPassword: value,
    }));
  };
  const handleEmailAddressChange = (event) => {
    const value = event.target.value;
    setUpdateDetails((prevState) => ({
      ...prevState,
      email: value,
    }));
  };
  const handleVerificationCodeChange = (event) => {
    const value = event.target.value;
    setUpdateDetails((prevState) => ({
      ...prevState,
      verificationCode: value,
    }));
  };
  return (
    <div className="flex justify-center items-center">
      <form
        className="w-1/2 shadow-lg"
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          BookingApi.forgotPassword(updateDetails)
            .then((e) => {
              console.log(e.data);
              console.log(e.status);
              if (e.status == "success") {
                setLoading(false);
                alert("Password Updated successfully");
                navigate("/signin");
              }

              if (e.status == "failed") {
                setLoading(false);
                alert(e.errorMessage);
              }
              return e.data;
            })
            .catch((e) => {
              setLoading(false);
              console.log(e);
            });
        }}
      >
        <AppCards className="box-border p-4">
          <header className="flex flex-col gap-2 box-border py-2">
            <h2 className="w-full  text-xl  font-bold text-blue ">
              {t("Change Password")}
            </h2>
          </header>
          <div className=" m-6 mb-11">
            <Grid item xs={12} sm={6} className="m-7">
              <TextField
                name="verificationCode"
                type={"text"}
                id="verificationCode"
                margin="normal"
                required
                fullWidth
                label={t("Verification Code")}
                onChange={handleVerificationCodeChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="emailAddress"
                type={"text"}
                id="emailAddress"
                margin="normal"
                required
                fullWidth
                label={t("Email Address")}
                onChange={handleEmailAddressChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                type={"password"}
                required
                fullWidth
                label="New Password"
                name="newPassword"
                id="newPassword"
                onChange={handleNewPasswordChange}
              />
            </Grid>
            <div className="w-full  ">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "#020D53",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  "&:hover": {
                    background: "#EE4B2B",
                  },
                }}
              >
                {t("Confirm")}
              </Button>
            </div>
          </div>
        </AppCards>
      </form>
    </div>
  );
}
