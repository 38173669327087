import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BookingApi } from "../../API/Apis/BookingApi";
import { t } from "i18next";
import TablePagination from "@mui/material/TablePagination";

export default function InvoiceCard() {
  const [invoiceList, setInvoiceList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalInvoices, setTotalInvoices] = useState(0);

  const handlePageChange = (event: unknown, page: number) => {
    setPage(page);
  };

  const handleRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadInvoice = (invoiceId) => {
    if (invoiceId == "No Invoice Found") {
      alert("No Invoice Found");
    } else {
      BookingApi.downloadInvoice(invoiceId).then((e) => {
        if (e.data.status == "error") {
          alert(e.data.errorMessage);
        } else {
          const url = e.data;
          window.open(url, "_blank");
        }
      });
    }
  };

  useEffect(() => {
    setLoader(true);
    const id: any = localStorage.getItem("userID");
    const offset = page * rowsPerPage;
    BookingApi.getOnlineUserInvoices(id, offset, rowsPerPage).then((e) => {
      setTotalInvoices(e.data.total);
      setInvoiceList(e.data.invoiceList);
      setLoader(false);
    });
  }, [page, rowsPerPage]);
  return (
    <>
      {loader ? (
        <Typography className="!text-center  !text-3xl !flex !justify-center  ">
          Loading...
        </Typography>
      ) : (
        <div>
          <TableContainer>
            <Table
              className="!border-2  rounded-2xl"
              aria-label="simple table"
              stickyHeader
            >
              <TableHead className="!bg-[#3c63ae] text-[24px] ">
                <TableRow className="!bg-[#3c63ae] rounded-2xl">
                  <TableCell className="!bg-[#3c63ae] !text-[#fff] !text-[20px] rounded-tl-2xl">
                    {t("Invoice Number")}
                  </TableCell>
                  <TableCell className="!bg-[#3c63ae] !text-[#fff] !text-[20px] ">
                    {t("Start Date")}
                  </TableCell>
                  <TableCell className="!bg-[#3c63ae] !text-[#fff] !text-[20px]">
                    {t("End Date")}
                  </TableCell>
                  <TableCell className="!bg-[#3c63ae] !text-[#fff] !text-[20px]">
                    {t("Invoice Amount")}
                  </TableCell>
                  <TableCell className="!bg-[#3c63ae] !text-[#fff] !text-[20px]">
                    {t("Status")}
                  </TableCell>
                  <TableCell className="!bg-[#3c63ae] !text-[#fff] !text-[20px] rounded-tr-2xl">
                    {t("Download")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceList.map((e: any, i) => (
                  <TableRow key={i}>
                    <TableCell
                      className="!text-[14px] text-[#333]"
                      component="th"
                      scope="e"
                    >
                      <ul>
                        <li className="!text-[#333]">
                          {e.prefix + "-" + e.invoiceNumber || ""}
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell
                      className="!text-[14px] text-[#333]"
                      component="th"
                      scope="e"
                    >
                      <ul>
                        <li className="!text-[#333]">{e.startDate || ""}</li>
                      </ul>
                    </TableCell>
                    <TableCell
                      className="!text-[14px] text-[#333]"
                      component="th"
                      scope="e"
                    >
                      <ul>
                        <li className="!text-[#333]">
                          {e.invoiveEndDate || ""}
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell
                      className="!text-[14px] text-[#333]"
                      component="th"
                      scope="e"
                    >
                      <ul>
                        <li className="!text-[#333]">{e.totInvoice || ""}</li>
                      </ul>
                    </TableCell>
                    <TableCell
                      className="!text-[14px] text-[#333]"
                      component="th"
                      scope="e"
                    >
                      <ul>
                        <li
                          className="!text-[#333]"
                          style={{
                            backgroundColor: e.isPaid
                              ? "lightgreen"
                              : "#FF634748",
                            color: e.isPaid ? "green" : "red",
                            textAlign: "center",
                            padding: "10px",
                            borderRadius: "1rem",
                          }}
                        >
                          {(e.isPaid ? "Paid" : "Unpaid") || ""}
                        </li>
                      </ul>
                    </TableCell>

                    <TableCell>
                      <button
                        className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                        onClick={() => downloadInvoice(e.id)}
                      >
                        Invoice{" "}
                        <img
                          className="h-6"
                          src="/assets/images/Invoice.svg"
                          alt="Invoice Icon"
                        />
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalInvoices}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPage}
          />
        </div>
      )}
    </>
  );
}
