import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Profile } from "../../../views/profile/profile";
import BookingApi from "../../../API/Apis/BookingApi";
import { log } from "console";
import { t } from "i18next";

function BookingDetalisListSideNavigation() {
  const navigateTo = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    BookingApi.getUserProfileByUserId(id).then((e) => {
      // console.log(e.data.onlineUser[0]);
      setUserData(e.data.onlineUser[0]);
    });
  }, []);

  const activeClick = (item) => {
    setActiveItem(item);
  };

  function OnBookingDetalis() {
    navigateTo("/my-reservations");
  }

  function OncustomerDetalis() {
    navigateTo("/customerDetailsList");
  }
  function OnProfile() {
    navigateTo("/profile2");
  }

  function handleReservationListEvent() {
    navigateTo("/myReservedBookings");
  }

  function handleRentalListEvent() {
    navigateTo("/myRentals");
  }

  function handleInvoiceListEvent() {
    navigateTo("/myInvoices");
  }

  return (
    <div className="pt-8 mt-0 text-[#020D53] shadow-[5px_0px_16px_3px_#00000024] shadow-right text-base px-10 py-0 shadow-grey mt-2 max-w-376 max-h-screen">
      <div className="pb-8 pt-2">
        <ul className="flex items-center gap-4 mt-4">
          <li>
            <Avatar
              alt={`${userData.firstName}`}
              src="/assets/images/user.png"
            />
          </li>
          <li
            onClick={() => {
              OnProfile();
              activeClick("item1");
            }}
            className={`text-[#020D53] font-poppins_regular text-[20px] cursor-pointer ${
              activeItem === "item1" ? "active:bg-violet-700" : ""
            }`}
          >
            {userData.firstName + " " + userData.lastName}
          </li>
        </ul>
      </div>
      <hr></hr>
      <div className="mt-6">
        <ul className="flex flex-col gap-4 text-base ">
          {/* <a onClick={OnBookingDetalis} href="">
            <li className="flex items-center gap-4  hover:bg-[#EFEFEF] rounded-2xl w-200 p-2">
              <img
                className="h-12 "
                src="/assets/images/Booking details.svg"
                alt="Booking detalis icons"
              />
              Booking Details
            </li>
          </a> */}
          <li
            onClick={() => {
              OncustomerDetalis();
              activeClick("item2");
            }}
            // onClick={OncustomerDetalis}
            // className="flex items-center gap-4 hover:bg-[#EFEFEF] rounded-2xl p-2"
            className={`flex items-center gap-4 hover:bg-[#EFEFEF] cursor-pointer rounded-2xl p-2 ${
              activeItem === "item2" ? "active:bg-violet-700" : ""
            }`}
          >
            <img
              className="h-12"
              src="/assets/images/Customer details.svg"
              alt="Customer Detalis"
            />
            {t("Customer Details")}
          </li>
          <li
            onClick={handleReservationListEvent}
            className="flex items-center gap-4 hover:bg-[#EFEFEF] cursor-pointer rounded-2xl p-2"
          >
            <img
              className="h-12"
              src="/assets/images/List of reservation.svg"
              alt="List of reservation"
            />
            {t("List of reservation")}
          </li>
          {/*<a href="">*/}
          {/*  <li*/}
          {/*    onClick={handleRentalListEvent}*/}
          {/*    className="flex items-center gap-4 hover:bg-[#EFEFEF] rounded-2xl p-2"*/}
          {/*  >*/}
          {/*    <img*/}
          {/*      className="h-12"*/}
          {/*      src="/assets/images/List of rentals.svg"*/}
          {/*      alt="List of rentals"*/}
          {/*    />*/}
          {/*    List of rentals*/}
          {/*  </li>*/}
          {/*</a>*/}
          <li
            onClick={handleInvoiceListEvent}
            className="flex items-center gap-4 hover:bg-[#EFEFEF] cursor-pointer rounded-2xl p-2 mb-20"
          >
            <img
              className="h-12"
              src="/assets/images/list of invoice.svg"
              alt="List of invoice"
            />
            {t("List of invoice")}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BookingDetalisListSideNavigation;
