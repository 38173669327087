import { FormattedPrice } from "../@types/FormattedPrice";

export const formatPriceWithCurrencySymbol = (
  currencyCode = "EUR",
  price = 0
  //locale = navigator.language // making symbol hardcoded
): FormattedPrice => {
  const priceWithSymbol = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
    currencySign: "standard",
    localeMatcher: "lookup",
    compactDisplay: "long",
  }).format(price);
  const symbol: string = priceWithSymbol
    .replace(/\d/g, "")
    .replace(/,/g, "")
    .replace(/\./g, "")
    .trim();
  return {
    symbol,
    price: priceWithSymbol.replace(symbol, ""),
    original: priceWithSymbol,
  };
};
