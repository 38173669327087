import { ReactNode } from "react";

export function FormFieldErrorMessage({
  className = "",
  children,
}: {
  className?: string;
  children?: ReactNode | ReactNode[];
}) {
  return (
    <>
      {children && (
        <div
          className={
            " rounded-md box-border text-sm h-full w-full text-red-600" +
            className
          }
        >
          {children}
        </div>
      )}
    </>
  );
}
