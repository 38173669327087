import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import states from "./states";
const SelfServiceSlice = createSlice({
  name: "SelfService",
  initialState: states,
  reducers: reducers,
});
export const SelfServiceActions = SelfServiceSlice.actions;
export default SelfServiceSlice.reducer;
