import React, { forwardRef } from "react";

import { AppTimePickerProps } from "./AppTimeSelect.d";
import SlotTimePicker from "react-datepicker";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";

export const AppTimePickerMaterial = forwardRef(AppTimePickerMaterialComponent);
export function AppTimePickerMaterialComponent(
  { className, ...props }: AppTimePickerProps,
  ref
) {
  return (
    <div className={"w-full " + className}>
      <TimePicker
        {...props}
        renderInput={(params) => <TextField {...params} fullWidth />}
        inputRef={ref}
      />
    </div>
  );
}
export const AppTimePickerStandard = forwardRef(AppTimePickerStandardComponent);
export function AppTimePickerStandardComponent(
  {
    className,
    onChange = () => {},
    enableTimesInRange = [],

    ...props
  }: AppTimePickerProps,
  ref
) {
  return (
    <div className={"w-full " + className}>
      <SlotTimePicker
        {...props}
        onChange={(e) => {
          onChange(e);
        }}
        // withPortal
        selected={props.value}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        //dateFormat="hh:mm aa" (For 12 hour format Fleetmanager)

        dateFormat="HH:mm"
        timeFormat="HH:mm" //for 24hr format
        filterTime={(x: Date) => {
          const isEnabled = enableTimesInRange.reduce((e, v) => {
            const isPast = x.getTime() <= v.start.getTime();
            const isFuture = x.getTime() >= v.end.getTime();
            return e || (!isFuture && !isPast);
          }, false);

          return isEnabled;
        }}
        customInput={
          <TextField
            inputRef={ref}
            InputProps={{ readOnly: true }}
            label={props.label}
            required={props.required}
            fullWidth
          />
        }
        timeCaption={props.label}
      />
    </div>
  );
}
