import { makeStyles } from "@mui/styles";
const useMultiSelectCheckBoxStyles = makeStyles(() => ({
  default(e) {
    return {
      "& .MuiInputLabel-root": {
        position: "unset",
        transform: "unset",
        width: "100%",
        height: "auto",
        display: "flex",
        alignItems: "center",
        whiteSpace: "normal",
        overflow: "unset",
        "&.Mui-focused": {
          color: "rgba(0, 0, 0, 0.6)",
        },
      },

      "&.MuiFormControl-root": {
        height: "auto",
        display: "flex ",
        width: "auto",
      },
    };
  },
}));
export { useMultiSelectCheckBoxStyles };
