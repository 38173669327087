import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { AppInputSwitchProps } from "../components/Layouts/AppInputSwitcher/AppInputSwitcher.props";
import BookingApi from "../API/Apis/BookingApi";
import { isBookingForm } from "../views/pay";
import { SingleFormFieldType } from "../components/UI/AppForm/AppForm.props";
import { UseFormReturn } from "react-hook-form/dist/types";
import { BO } from "country-flag-icons/react/3x2";
import { WebCheckInFormFields } from "../components/Layouts/WebCheckInForms/FormOptions";

export const useWebCheckInForm = (
  initialFormFields: Record<
    string,
    AppInputSwitchProps | SingleFormFieldType
  > = {},
  initialValues: Record<string, any> = {},
  onChange: (e: Record<any, any>) => void = () => {}
): [UseFormReturn<Record<string, any>>, Record<any, any>] => {
  const [formFields, setFormFields] = useState({ ...initialFormFields });
  const form: UseFormReturn<Record<string, any>> = useForm<
    typeof initialValues
  >({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...initialValues,
    },
  });
  const VALUES = useWatch({
    control: form.control,
  });
  const currentSsnValue = form.watch(formFields?.ssn?.key);
  const currentCountryValue = form.watch(formFields?.country?.key);
  const currentStateValue = form.watch(formFields?.state?.key);
  const currentCityValue = form.watch(formFields?.city?.key);
  const currentBirthNationValue = form.watch(formFields?.birthNation?.key);

  const currentBirthPlaceValue = VALUES[formFields?.birthPlace?.key];
  const currentFirstNameValue = VALUES[formFields?.firstName?.key];
  const currentLastNameValue = VALUES[formFields?.lastName?.key];
  const currentNationValue = VALUES[formFields?.country?.key];
  const currentSexValue = VALUES[formFields?.gender?.key];
  const currentDobValue: Date = VALUES[formFields?.birthDate?.key];

  const currentBirthPlaceCompanyValue = VALUES[formFields?.birthPlace?.key];
  const currentFirstNameCompanyValue =
    VALUES[formFields?.IndividualFirstName?.key];
  const currentLastNameCompanyValue =
    VALUES[formFields?.IndividualLastName?.key];
  const currentNationCompanyValue = VALUES[formFields?.country?.key];
  const currentSexCompanyValue = VALUES[formFields?.IndividualGender?.key];
  const currentDobCompanyValue: Date =
    VALUES[formFields?.CompanyBirthDate?.key];
  const currentBirthNationCompanyValue: Date =
    VALUES[formFields?.birthNation?.key];

  const isCountryItaly = form
    ?.watch(formFields?.country?.key)
    ?.toLowerCase()
    .includes("ital");

  const isCompanyItaly = form
    ?.watch(formFields?.country?.key)
    ?.toLowerCase()
    .includes("ital");

  function setCountries() {
    const tempFields = { ...formFields };
    if (tempFields.state) {
      tempFields.state.type = "text";
      tempFields.state.variant = "standard";
      tempFields.state.readOnly = false;
      if (isCountryItaly && currentStateValue?.toLowerCase().includes("ee")) {
        form.setValue(tempFields.state.key, "");
      }
    }
    if (tempFields.city) {
      tempFields.city.type = "text";
      tempFields.city.variant = "standard";
      tempFields.city.readOnly = false;
      if (isCountryItaly && currentCityValue?.toLowerCase().includes("ee")) {
        form.setValue(tempFields.city.key, "");
      }
    }
    if (tempFields.zip) {
      tempFields.zip.readOnly = true;
      tempFields.zip.type = "text";
      tempFields.zip.variant = "standard";
    }
    if (tempFields.birthNation) {
      tempFields.birthNation.type = "text";
      tempFields.birthNation.variant = "standard";
      tempFields.birthNation.readOnly = true;
    }
    if (isCountryItaly) {
      // form.setValue(formFields?.state?.key, "");
      // form.setValue(formFields?.city?.key, "");
      form.setValue(formFields?.zip?.key, "");
    }
    if (!isCountryItaly) {
      // form.setValue(formFields?.state?.key, "");
      // form.setValue(formFields?.city?.key, "");
      form.setValue(formFields?.zip?.key, "");
      // form.setValue(formFields?.ssn?.key, "9999999999999999");
      if (tempFields.zip) {
        tempFields.zip.readOnly = false;
      }
      // if (tempFields.city) {
      //   tempFields.city.readOnly = false;
      // }
      // if (tempFields.state) {
      //   tempFields.state.readOnly = false;
      // }
      // if (tempFields.ssn) {
      //   tempFields.ssn.type = "text";
      //   tempFields.ssn.variant = "standard";
      //   // tempFields.ssn.readOnly = true;
      // }
    } else {
      if (tempFields.ssn) {
        tempFields.ssn.type = "text";
        tempFields.ssn.variant = "standard";
        // tempFields.ssn.readOnly = true;
      }
    }
    setFormFields({
      ...tempFields,
    }); // disabling country, state, city, zip and birth nation until country options are fetched

    BookingApi.getAllCountries().then(async (data) => {
      const allCountries = data.data || [];
      if (allCountries.length) {
        if (isCountryItaly) setCity();
        if (tempFields.birthNation) {
          tempFields.birthNation.type = "select";
          tempFields.birthNation.variant = "with-search";
          tempFields.birthNation.readOnly = false;
          tempFields.birthNation.options = allCountries;
        }
        if (tempFields.country) {
          tempFields.country.type = "select";
          tempFields.country.variant = "with-search";
          tempFields.country.readOnly = false;
          tempFields.country.options = allCountries;
        }
      }
      setFormFields({
        ...tempFields,
      });
    });
  }
  function setStateAndZip(
    cityName: string = form.getValues(formFields?.city?.key) || ""
  ) {
    if (!cityName || cityName.toLowerCase() == "ee") {
      return;
    }
    if (isCountryItaly) {
      const tempFields = { ...formFields };
      if (tempFields.zip) {
        tempFields.zip.type = "text";
        tempFields.zip.readOnly = false;
      }
      if (tempFields.state) {
        tempFields.state.type = "text";
        tempFields.state.readOnly = false;
      }
      if (tempFields.birthPlace) {
        tempFields.birthPlace.type = "text";
      }
      BookingApi.getCity(cityName).then((data) => {
        const stateOptions = data.data.state.filter(
          (e) => !e.toLowerCase().includes("xxx")
        );
        const zipOptions = data.data.zip.filter(
          (e) => !e.toLowerCase().includes("xxx")
        );
        if (!zipOptions.length && !stateOptions.length) return;

        if (tempFields.state) {
          tempFields.state.options = stateOptions;
          tempFields.state.readOnly = false;
          tempFields.state.type = "select";
          tempFields.state.variant = "with-search";
        }
        if (tempFields.zip) {
          tempFields.zip.options = zipOptions;
          tempFields.zip.readOnly = false;
          tempFields.zip.type = "select";
          tempFields.zip.variant = "with-search";
        }
        setFormFields({ ...tempFields });
      });
      setFormFields({ ...tempFields });
    } else {
      return;
    }
  }

  function setBirthProv(BirthProvName: string = currentBirthPlaceValue || "") {
    if (!BirthProvName || BirthProvName.toLowerCase() == "ee") {
      return;
    }
    if (isCompanyItaly) {
      const tempFields = { ...formFields };
      if (tempFields.birthProv) {
        tempFields.birthProv.type = "text";
        tempFields.birthProv.readOnly = false;
      }
      BookingApi.getCity(BirthProvName).then((data) => {
        const stateOptions = data.data.state.filter(
          (e) => !e.toLowerCase().includes("xxx")
        );
        if (!stateOptions.length) {
          return;
        }
        if (tempFields.birthProv) {
          tempFields.birthProv.options = stateOptions;
          tempFields.birthProv.type = "select";
          tempFields.birthProv.variant = "with-search";
        }
        if (tempFields.CompanyBirthProv) {
          tempFields.CompanyBirthProv.options = stateOptions;
          tempFields.CompanyBirthProv.type = "select";
          tempFields.CompanyBirthProv.variant = "with-search";
        }
        setFormFields({ ...tempFields });
      });
      setFormFields({ ...tempFields });
    } else {
      return;
    }
  }
  // function setBirthNation(
  //   countryName: string = form.getValues(formFields?.country?.key) || ""
  // ) {}
  function setCity(
    countryName: string = form.getValues(formFields?.country?.key) || ""
  ) {
    if (
      (countryName && !countryName?.toLowerCase()?.includes("ital")) ||
      !countryName
    ) {
      return;
    }
    const tempFields = { ...formFields };
    if (tempFields.state) {
      tempFields.state.type = "text";
      tempFields.state.readOnly = false;
    }
    if (tempFields.city) {
      tempFields.city.type = "text";
      tempFields.city.readOnly = false;
    }
    if (tempFields.zip) {
      tempFields.zip.type = "text";
      tempFields.zip.readOnly = false;
    }
    if (countryName != "") {
      BookingApi.getStates(countryName).then((data) => {
        setStateAndZip();
        const allCityOptions = data.data;
        if (!allCityOptions.length) {
          return;
        }
        if (tempFields.city) {
          tempFields.city.type = "select";
          tempFields.city.variant = "with-search";
          tempFields.city.readOnly = false;
          tempFields.city.options = allCityOptions;
        }

        setFormFields({ ...tempFields });
      });
    }

    setFormFields({ ...tempFields });
  }
  function setBirthDetailsUsingSsn() {
    const isUserDetailsAlreadyPresent: boolean = !!(
      currentFirstNameValue &&
      currentLastNameValue &&
      currentBirthNationValue &&
      currentBirthPlaceValue &&
      currentSexValue &&
      currentDobValue &&
      currentNationValue
    );
    if (isUserDetailsAlreadyPresent) {
      return;
    }
    if (currentSsnValue?.length !== 16) {
      form.setError(
        formFields?.ssn?.key,
        {
          type: "invalidSSn",
          message: "Please provide a valid ssn",
        },
        {
          shouldFocus: true,
        }
      );
      return;
    }
    form.clearErrors(formFields?.ssn?.key);
    BookingApi.getItalianCustomerDetailsBySSN(currentSsnValue)
      .then((e) => {
        if (e.data?.ninedigit) {
          form.setError(
            formFields?.ssn?.key,
            {
              type: "invalidSSn",
              message: "Please provide a valid ssn",
            },
            {
              shouldFocus: true,
            }
          );
          return;
        }
        if (formFields?.birthProv && e.data?.birthProvince) {
          form.setValue(formFields.birthProv.key, e.data.birthProvince);
        }
        if (formFields.birthNation && e.data?.birthNation) {
          form.setValue(formFields.birthNation.key, e.data.birthNation);
        }
        if (formFields?.birthPlace && e.data?.birthPlace) {
          form.setValue(formFields.birthPlace.key, e.data.birthPlace);
        }
        if (formFields?.gender && e.data?.gender) {
          form.setValue(
            formFields.gender.key,
            e.data.gender.toLowerCase() == "m" ? "MALE" : "FEMALE"
          );
        }
        if (formFields.birthDate) {
          form.setValue(formFields.birthDate.key, e.data.dataofbirth);
        }
      })
      .catch();
  }
  useEffect(() => {
    if (isCountryItaly) {
      setCountries();
    } else {
      setCity();
    }
  }, []);

  useEffect(() => {
    // console.log(form.getValues(formFields?.birthNation?.key));
    BookingApi.getStates(form.getValues(formFields?.birthNation?.key))
      .then((e) => {
        const allCityOptions = e.data;
        const tempFields = { ...formFields };
        if (tempFields.birthPlace) {
          form.setValue(WebCheckInFormFields?.birthPlace?.key, "");
          const isBirthNationCountryItaly = form
            ?.getValues(WebCheckInFormFields?.birthNation?.key)
            ?.toString()
            ?.toLowerCase()
            ?.includes("ital");

          if (!isBirthNationCountryItaly) {
            tempFields.birthPlace.options = [];
            tempFields.birthPlace.type = "text";
            tempFields.birthPlace.variant = "material";
            tempFields.birthPlace.readOnly = false;
          } else {
            tempFields.birthPlace.options = allCityOptions;
            tempFields.birthPlace.type = "select";
            tempFields.birthPlace.variant = "with-search";
            tempFields.birthPlace.readOnly = false;
          }
          setFormFields({ ...tempFields });
        }
      })
      .catch();
  }, [currentBirthNationValue]);
  // on mounted

  useEffect(() => {
    const API_CALL = setTimeout(() => {
      setCountries();
      setCity(currentCountryValue);
    }, 500);
    return () => {
      clearTimeout(API_CALL);
    };
  }, [currentCountryValue]);
  // country value watch
  useEffect(() => {
    const API_CALL = setTimeout(() => {
      setStateAndZip();
    }, 500);
    return () => {
      clearTimeout(API_CALL);
    };
  }, [currentCityValue]);
  // city value watch
  useEffect(() => {
    const API_CALL = setTimeout(() => {
      if (isCountryItaly) {
        setBirthDetailsUsingSsn();
      }
    }, 500);
    return () => {
      clearTimeout(API_CALL);
    };
  }, [currentSsnValue]);
  // ssn value  watch ENDS HERE

  useEffect(() => {
    onChange(VALUES);
    // console.log(VALUES);
  }, [VALUES]);

  // Logic for finding the SSN for customer details
  useEffect(() => {
    const ssnKey = formFields?.ssn?.key;
    const shouldFindSsn: boolean = !!(
      currentFirstNameValue &&
      currentLastNameValue &&
      currentBirthNationValue &&
      currentBirthPlaceValue &&
      currentSexValue &&
      currentDobValue &&
      currentNationValue
    );
    form.clearErrors(ssnKey);
    let timer: null | NodeJS.Timeout = null;
    form.setValue(ssnKey, "");
    if (formFields?.ssn) {
      formFields.ssn.readOnly = false;
    }

    if (shouldFindSsn && isCountryItaly && !isBookingForm) {
      timer = setTimeout(() => {
        BookingApi.getSsnByCustomerDetails(
          currentFirstNameValue,
          currentLastNameValue,
          currentBirthNationValue,
          currentBirthPlaceValue,
          currentSexValue,
          currentDobValue,
          currentNationValue
        )
          .then((e) => {
            form.setValue(ssnKey, e.data, { shouldValidate: true });
          })
          .catch(() => {
            form.setError(ssnKey, {
              type: "invalidSSN",
              message: "Unable to find ssn",
            });
          });
      }, 100);
    } else if (shouldFindSsn && !isCountryItaly) {
      form.setValue(ssnKey, "9999999999999999");
      formFields.ssn.readOnly = true;
    }
    return () => {
      //    console.log("CLEARING");
      if (timer) clearTimeout(timer);
    };
  }, [
    currentFirstNameValue,
    currentLastNameValue,
    currentBirthNationValue,
    currentBirthPlaceValue,
    currentSexValue,
    currentDobValue,
    currentNationValue,
  ]);
  return [form, formFields];
};
