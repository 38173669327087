import React from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
function ProfileNavigation() {
  const navigateTo = useNavigate();
  const isLogged = localStorage.getItem("isUserLoggedin");

  function onLogout() {
    if (isLogged == "true") {
      localStorage.setItem("isUserLoggedin", "false");
      localStorage.removeItem("userID");
      localStorage.removeItem("customerId");
      navigateTo("/signin");
    } else {
      alert("you are already logged out");
    }
  }
  function handlePersonalInfo() {
    navigateTo("/profile");
  }

  function handleChangePassword() {
    navigateTo("/changePassword");
  }

  return (
    <div className="text-[#020D53] p-12 shadow-[5px_0px_16px_3px_#00000024]">
      <div>
        <ul>
          <li className="text-[32px] tracking-wide font-medium">
            {t("My Profile")}
          </li>
        </ul>
      </div>
      <div>
        <ul className="flex flex-col gap-4 mt-8 text-[20px]">
          <a
            className="hover:bg-[#EFEFEF] rounded-2xl w-200 p-2"
            onClick={handlePersonalInfo}
          >
            {t("Personal information")}
          </a>
          <a
            className="hover:bg-[#EFEFEF] rounded-2xl w-200 p-2"
            onClick={handleChangePassword}
          >
            {t("Change password")}
          </a>
          {/*<a className="hover:bg-[#EFEFEF] rounded-2xl w-200 p-2" href="">*/}
          {/*  Payment method*/}
          {/*</a>*/}
          <a
            onClick={onLogout}
            className="hover:bg-[#EFEFEF] rounded-2xl w-200 p-2"
          >
            {t("Logout")}
          </a>
        </ul>
      </div>
    </div>
  );
}

export default ProfileNavigation;
