import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Controller,
  Swiper as Swipertype,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useState } from "react";
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { CarouselProps } from "./Carousel.props";
import React from "react";

function Carousel({
  carouselItems = [],
  onSlideItemClick,
  direction = "vertical",
}: CarouselProps) {
  const prevButtonClass: string =
    "prev_" + Math.floor(Math.random() * 100) + "_" + Date.now();
  const nextButtonClass: string =
    "next_" + Math.floor(Math.random() * 100) * 100 + "_" + Date.now();
  const { pagination, navigation, onSlideChange, slidesPerView } = {
    pagination: { clickable: true },
    navigation: {
      nextEl: `.${nextButtonClass}`,
      prevEl: `.${prevButtonClass}`,
    },
    onSlideChange: () => {},
    slidesPerView: 3,
  };
  const [, setCarouselEl] = useState<Swipertype>();
  return (
    <div
      className={`flex ${
        direction == "vertical" ? "flex-col" : "flex-row"
      } w-full h-full`}
    >
      {direction == "vertical" ? (
        <button className={prevButtonClass}>
          <KeyboardArrowUp />
        </button>
      ) : (
        <button className={prevButtonClass}>
          <KeyboardArrowLeft />
        </button>
      )}

      <Swiper
        className={`flex ${
          direction == "vertical" ? "flex-col" : "flex-row"
        } w-full h-full`}
        onInit={(e) => {
          setCarouselEl(e);
        }}
        direction={direction}
        modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
        spaceBetween={2}
        slidesPerView={slidesPerView}
        navigation={navigation}
        pagination={!pagination}
        onSlideChange={onSlideChange}
      >
        {carouselItems.map((el: JSX.Element, index: number) => {
          return (
            <SwiperSlide
              key={index}
              style={{ margin: 0, width: "100%", height: "100%" }}
              onClick={() => {
                onSlideItemClick(index);
              }}
            >
              <div className="w-full h-full">{el}</div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {direction == "vertical" ? (
        <button className={nextButtonClass}>{<KeyboardArrowDown />}</button>
      ) : (
        <button className={nextButtonClass}>
          <KeyboardArrowRight />
        </button>
      )}
      {/*<div*/}
      {/*  className={*/}
      {/*    "pagination w-full  flex items-center justify-center flex-wrap gap-2"*/}
      {/*  }*/}
      {/*></div>*/}
    </div>
  );
}
export { Carousel };
