const WebCheckInReducers = {
  setIsAvailable(state, { payload: value }) {
    state.isAvailable = !!value;
  },
  setBookingDetails(state, { payload: value }) {
    // console.log(value);
    // state.bookingDetails = value;
  },
};
export { WebCheckInReducers };
export default WebCheckInReducers;
