import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { BookingApi } from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import ReservationCard from "../../components/Layouts/ReservationsCard/ReservationsCards";

export default function MyRentals(props) {
  const [showRental, setShowRental] = useState(false);
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowRental(e.reservedArea);
    });
  }, [showRental]);
  return (
    <div>
      <div className="flex">
        <BookingDetalisListSideNavigation />
        {showRental ? (
          <div>
            <div className="container  mx-4">
              <div className="flex flex-col   ">
                <div className="flex justify-between space-x-2 mb-8  ">
                  <div>
                    <h1 className="font-medium text-[36px] text-[#232323]">
                      Rentals List
                    </h1>
                    <h4 className="text-[#838181] font-normal text-[20px]">
                      View Your Rentals List
                    </h4>
                  </div>
                </div>
                <div className={"block"}>
                  <ReservationCard callFrom={"rentals"}></ReservationCard>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NotAuthorisedPage />
        )}
      </div>
    </div>
  );
}
