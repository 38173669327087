import {
  DefaultValuesDetailsForm,
  IndividualFormProps,
} from "../../Layouts/WebCheckInForms/WebCheckInForms.props";
import {
  ReadOnlyIfInitialValuesNotProvided,
  SoleTraderFormFields,
} from "../../Layouts/WebCheckInForms/FormOptions";

import { AppInputSwitchProps } from "../../Layouts/AppInputSwitcher/AppInputSwitcher.props";
import { AppInputSwitcher } from "../../Layouts/AppInputSwitcher/AppInputSwitcher";
import { FormFieldErrorMessage } from "../FormFieldErrorMessage/FormFieldErrorMessage";
import React from "react";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { SelfDriverFormFields } from "../../Layouts/WebCheckInForms/FormKeys";
import { emailRegex } from "../../../@types/REGEX";
import { useTranslation } from "react-i18next";
import { useWebCheckInForm } from "../../../hooks/WebCheckInForm_2";

export function SoleTraderForm({
  values = { ...DefaultValuesDetailsForm },
  initialValues = { ...DefaultValuesDetailsForm },
  //onChange = () => {},
  children = <></>,
  onSubmit = () => {},
}: IndividualFormProps) {
  const [
    {
      register,
      formState: { errors },
      getValues,
      setValue,
      handleSubmit,
    },
    soleTraderFormFields,
  ] = useWebCheckInForm(SoleTraderFormFields, {
    ...values,
  });

  const { t: translate } = useTranslation();
  return (
    <form className={"flex flex-col gap-4"} onSubmit={handleSubmit(onSubmit)}>
      {Object.values(soleTraderFormFields).map((el: AppInputSwitchProps) => {
        const readOnly =
          el.readOnly ||
          (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
            !!getValues(el.key));
        const options: RegisterOptions = {
          required: {
            value: !!el.required,
            message: "This is a required field",
          },
        };
        if (el.type === "email") {
          options.pattern = {
            value: emailRegex,
            message: "Please provide a valid email",
          };
        }
        return (
          <div key={el.key}>
            <AppInputSwitcher
              {...el}
              {...register(el.key, {
                ...options,
              })}
              onChange={() => {}}
              className="h-[4.3rem]"
              readOnly={readOnly}
              label={translate(el.label || "")}
              required={false}
              placeholder={translate(el.placeholder || "")}
              options={el.options || []}
              value={getValues(el.key) || ""}
              onchange={async (e) => {
                setValue(el.key, e, {
                  shouldValidate: true,
                });
              }}
            />
            <FormFieldErrorMessage>
              {errors[el.key]?.message}
            </FormFieldErrorMessage>
          </div>
        );
      })}
      <div className={"flex flex-col gap-4"}>
        {getValues("isSignatoryTheMainDriver") === "YES" &&
          Object.values(SelfDriverFormFields).map(
            (el: AppInputSwitchProps, index: number) => {
              const readOnly =
                el.readOnly ||
                (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                  !!initialValues[el.key]);
              return (
                <div key={index}>
                  <AppInputSwitcher
                    {...el}
                    {...register(el.key, {
                      required: {
                        value: !!el.required,
                        message: "This is a required field",
                      },
                    })}
                    required={false}
                    onChange={() => {}}
                    label={translate(el.label || "")}
                    readOnly={readOnly}
                    value={getValues(el.key) || ""}
                    key={el.key + index}
                    className={"h-[4.3rem]"}
                    onchange={(e: string) => {
                      setValue(el.key, e, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <FormFieldErrorMessage>
                    {errors[el.key]?.message}
                  </FormFieldErrorMessage>
                </div>
              );
            }
          )}
      </div>
      {children}
    </form>
  );
}
