import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";

export const UseInputStyles = makeStyles((theme: Theme) => {
  return {
    standard: () => ({
      "& .MuiInputBase-input": {
        position: "unset",
        height: "100%",
        transform: "unset",
        borderRadius: "0.2rem",
        border: "1px solid " + theme.palette.inputFieldBorder.main,
        boxSizing: "border-box",
        padding: "0 0 0 0.5rem",
        margin: 0,
      },
      "& .MuiInputBase-root": {
        flexGrow: "1",
        display: "block",
        padding: "0",
        margin: 0,
        boxSizing: "border-box",
        paddingBottom: "0.8em",
      },
      "& .MuiInputLabel-root ": {
        width: "100%",
        fontSize: "1rem",
        transform: "unset",
        display: "block",
        position: "unset",
        height: "2rem",
      },
      "&.MuiFormControl-root": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "&:after": {
          border: "2px solid green",
        },
      },
    }),
    material() {
      return {
        "&.MuiFormControl-root ": {
          height: "100%",
        },
        "& .MuiOutlinedInput-root": {
          height: "100%",
        },
      };
    },
    password: () => ({
      "&.MuiFormControl-root": {
        height: "100%",
        paddingBottom: "0.8em",
      },
      "& .MuiInputLabel-root": {
        minHeight: "2rem",
        fontSize: "1rem",
        position: "unset",
        transition: "unset",
        transform: "unset",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
      "& .MuiInputBase-input": {
        padding: "0",
        height: "100%",
      },
    }),
  };
});
